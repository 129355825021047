#tableDonation .react-grid-HeaderCell{
    background: #FFFFFF;
    border-right: none;
}

#tableDonation .react-grid-Header{
    display: none !important;
    border: none;
}
#tableDonation .react-grid-Viewport {
    top: 0px !important;
}