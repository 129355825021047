#right-main-content {
    cursor: none;
}

#cursor-style {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FDEBCE;
    height: 2rem;
    width: 2rem;
    border-radius: 100%;
    transform-origin: 100% 100%;
    transform: translate(-50%, -50%);
    position: fixed;
    pointer-events: none;
    z-index: 3;
}

.element-deplacable {
  cursor: grab;

  &-post-it {
    cursor: grab;
    bottom: 60px;
  }
}
