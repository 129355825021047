.post-it-switch {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #D2E9F3;
  padding: 8px 4px 2px 5px;

  &-container {
    width: 20%;
  }
}

.post-it-commentary {
  border-top: 2px solid rgb(210,229,236);
  padding-top: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  height: 71%;

  @media (max-height: 832px) {
    height: 63%;
  }

  @media (max-height: 697px) {
    height: 58%;
  }
}
