$text-color : rgb(103,106,115);

.root-fiche{
    padding-left: 20px
}

.title{
    color: $text-color;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 5px
}

.subtitle{
    margin-bottom: 5px;
    color: $text-color;
    font-size: 16px;
    font-weight: bold
}

.text{
    color: $text-color;
    font-size: 14px
}

.stepsBloc{
    margin-top: 10px;
    font-size: 15px;
    font-Weight: 600px
}

.list{
    list-style: none;
    color: $text-color;
}

.lienAncre:hover{
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
}

.lien-partie:hover{
    text-decoration: underline;
    cursor: pointer;
}

.sommaire-fiche-thematique-content {
  display: flex;
  height: 100%;

  &-right {
    // flex: 0 0 12%;
    text-align: center;
    padding-top: 15px;

    &-button {
      cursor: pointer;
    }
  }
}

.fiche-thematique-content {
  &-left {
    flex: 0 0 var(--widthLeft);
    max-width: var(--widthLeft);
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    transition: all 0.5s;

    &-hidden {
      flex: 0 0 95%;
      max-width: 95%;
    }
  }

  &-right {
    position: fixed;
    right: 0;
    top: 116px;
    height: 100%;
    border-left: 1px solid #E0E4E6;
    background: #fff;
    padding-right: 0;
    width: 351px;
    transition: all 0.5s;
    // transition: opacity 1s;

    &-hidden {
      width: 42px;
    }

    &-scroll {
      height: 67%;

      @media (max-height: 832px) {
        height: 63%;
      }

      @media (max-height: 697px) {
        height: 56%;
      }
    }

    &-liste-ul {
      padding-left: 0;
      font-weight: 600;

      &-li-content {
        color: #585C66;
      }

      &-li-content:hover {
        background-color: #EBEBEB;
        cursor: pointer;
      }

      &-li-content-active {
        color: #E88AA0;
        background-color: #FFE5EA;
      }

      &-li-content-active:hover {
        color: #E88AA0;
        background-color: #FFE5EA;
      }

    }
  }
}
