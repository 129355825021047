.dropbtn {
    background-color: #FFFFFF;
    color: rgb(89,92,99);
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
  }

  .dropbtnActive {
    background-color: #FFFFFF;
    color: rgb(86,113,194);
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    //min-width: 134px;
    width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    border: 1px solid black;
    border-top: none;
  }
  
  .dropdown-content a {
    color: #707070;
    text-align: center;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
  }

  .dropdown-content a:not(:last-child) {
    border-bottom: 1px dashed #707070;
  }

  .left-arrow{
      content: '\25C0';
  }
  
  .dropdown-content a:hover {
      background-color: gray;
      color: #FFFFFF
}

.itemOnHover {
  background-color: gray;
  color: #FFFFFF !important
}
  
.dropdown:hover .dropdown-content {
    display: block;
}
  
  .dropdown:hover .dropbtn {
    color: rgb(86,113,194)
}