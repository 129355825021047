$element-dimesion: 50px;

.tableau-elements .titre{
    display: flex;
    border-bottom: 1px solid #DEDEDE;
    width: 100%;
    margin-bottom: 10px;
}

.tableau-elements .titre .colonne-titre-tableau{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: bold;
    padding: 10px;
    font-size: 13px;
}

.tableau-elements .ligne-tableau{
    display: flex;
    width: 100%
}

.tableau-elements .ligne-tableau:hover{
    cursor: pointer;
    background: #EDFBFF;
    font-weight: bold;
    border-radius: 20px;
}

.tableau-elements .ligne-tableau-type-client:hover{
    background: #FCF3FF;
}

.tableau-elements .ligne-tableau-type-fiche-thematique:hover{
    background: #FFECF0;
}

.tableau-elements .ligne-tableau .colonne-valeur-tableau{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    font-size: 13px;
}

.tableau-elements .ligne-tableau .colonne-valeur-tableau .element-with-cadre{
    display: flex; 
    align-items: center; 
    background: #D4D4D4; 
    font-weight: bold; 
    min-width: 80px; 
    max-width: 100%; 
    border-radius: 20px; 
    padding: 10px
}

.tableau-elements .ligne-tableau .colonne-valeur-tableau .element-with-cadre .bulle-ronde{
    width: 10px;
    height: 10px; 
    margin-right: 5px; 
    background: #333333; 
    border-radius: 10px
}

// Changement de couleur de fond des éléments à l'intérieure des ligns comme SUJET pour Fiche thématique et TYPE pour SIMULATIONS
.tableau-elements .ligne-tableau-type-simulation:hover .element-with-cadre{
    background: #1D51A1;
    color: #FFFFFF;
}

.tableau-elements .ligne-tableau-type-fiche-thematique:hover .element-with-cadre{
    background: #EF95AA;
    color: #FFFFFF;
}

.tableau-elements .ligne-tableau:hover .element-with-cadre .bulle-ronde{
    background: #FFFFFF
}

.contenu-pagination-tableau-liste-elements{
    display: flex
}

.contenu-pagination-tableau-liste-elements .element-pagination-active{
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    border-radius: 50%;
    font-weight: bold;
    font-size: 15px;
    color: #FFFFFF;
    background: linear-gradient(90deg, #1B6AAE, #17A3CA);
}

.contenu-pagination-tableau-liste-elements .element-pagination{
    width: $element-dimesion;
    height: $element-dimesion;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    font-size: 15px;
    font-weight: bold;
    color: #28366D;
}

.contenu-pagination-tableau-liste-elements .element-pagination:hover{
    cursor: pointer;
    transform: scale(1.05);
    transition: 0.6s
}