/* Dropdown Container */
.pole-dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Button */
.pole-dropdown-button {
  position: relative;
  padding: 8px 15px;
  background: #daf1e4;
  border: 1px solid #daf1e4;
  border-radius: 25px;
  cursor: pointer;
  width: 165px;
  text-align: left;
  color: #348152;
  font-weight: bold;
  z-index: 10;
}

/* Dropdown Caret */
.pole-dropdown-caret {
  float: right;
}

/* Dropdown Menu */
.pole-dropdown-menu {
  position: absolute;
  top: 10px;
  left: 0;
  background: #fff;
  border-radius: 25px;
  width: 165px;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); */
  box-shadow: rgba(105, 95, 151, 0.11) 2px 13px 54px;
  padding: 0;
  padding-top: 30px;
  margin: 0;
  list-style: none;
  z-index: 1;
}

/* Dropdown Option */
.pole-dropdown-option {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #f0f0f0;
  background: #fff;
  color: #348152;
}

.pole-dropdown-option:hover {
  background: #f7f7f7;
}

.pole-dropdown-option:last-child {
  border-radius: 0px 0px 25px 25px;
}

/* Ensure the radio button is hidden and the label will act as the custom radio button */
.pole-dropdown-option input[type="radio"] {
  display: none;  /*  Hide the default radio button */
}

/* Custom radio button styling */
.pole-dropdown-option label {
  position: relative;
  padding-left: 30px; /* Space for the square radio button */
  cursor: pointer;
  display: inline-block;
}

/* Create a square box for the radio button */
.pole-dropdown-option label::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%); /* Center the box vertically */
  width: 18px;
  height: 18px;
  border: 2px solid #348152; /* Border color for the square */
  background-color: white;
  border-radius: 4px; /* Optional: slightly rounded corners for the square */
}

/* Style for the checked state of the radio button */
.pole-dropdown-option input[type="radio"]:checked + label::before {
  background-color: #348152; /* Background color when checked */
  border-color: #daf1e4; /* Border color when checked */
}

/* Indicator inside the radio button when checked */
.pole-dropdown-option input[type="radio"]:checked + label::after {
  content: '';
  position: absolute;
  left: 4px;
  top: 4px;
  width: 8px;
  height: 8px;
  background-color: daf1e4; /* Circle inside the square when selected */
  border-radius: 50%;
}

