.cadreRougeBloc33{
    width: 70px;
    height: 5px;
    border-radius: 2px;
    background-color: rgb(241,99,96);
    margin-bottom: 17px;
}

.cadreRoseBloc33{
    width: 180px;
    height: 5px;
    border-radius: 2px;
    background-color: rgb(238,70,139);
    margin-bottom: 15px;
}

.cadreJauneBloc33{
    width: 260px;
    height: 5px;
    border-radius: 2px;
    background-color: rgb(237,196,19);
}

.formSelectBloc33{
    padding: 5px;
    width: 75px;
    border-radius: 15px;
    border: 1px solid rgb(88,92,102);
}