$dimension: 45px;

.element-calculatrice{
    width: $dimension;
    height: $dimension;
    background: #6F7992 0% 0% no-repeat padding-box;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    // box-shadow: 2px 0px 1px #5B647D;
}

.element-calculatrice:hover{
    opacity: .7;
    cursor: pointer;
}

// Les signes arithmétiques
.element-calculatrice-operation{
    width: $dimension;
    height: $dimension;
    background: #39465A 0% 0% no-repeat padding-box;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;

    &-orange {
      background-color: #57356A ;
    }

    &-orange:hover {
      opacity: 0.7;
    }
}

.element-calculatrice-operation:hover{
    opacity: .7;
    cursor: pointer;
}

.hide-calculator {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 13px;

  img {
    cursor: pointer;
    width: 17px;
  }
}

.calculator {

  &-container {
    background: transparent linear-gradient(144deg, #6E7A98 0%, #293450 100%) 0% 0% no-repeat padding-box;
    padding: 30px 20px;
    width: 294px;
    border-radius: 14px;
  }

  &-edit {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: white;
    font-weight: bold;
    font-size: 24px;
    overflow-x: auto;

    &-calcul {
      color: #98A7C8;
      cursor: context-menu;
      outline: none;
      caret-color: transparent;
      height: 33px;
      overflow-y: hidden;
    }

    &-result {
      border-bottom: 1px solid #98A7C8;
      margin-bottom: 10px;
      font-size: 34px;
      cursor: copy;
      height: 52px;
      overflow-y: hidden;
    }
  }

}
.calculator-element-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-row-gap: 8px;
  grid-column-gap: 7px;

  &-item:last-child {
    margin-right: 0px;
    grid-column-start: 4;
    grid-column-end: 6;
    width: 95px;
    border-radius: 10px;
    font-size: 2rem;
  }
}

.calculator-item-img-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.calculator-item-img {
  width: 28%;
}

.calculator-item-span-container {
  font-size: 1rem;
}

.center-second-column {
  padding-left: 2px;
}

.center-first-column {
  padding-right: 2px;
}
