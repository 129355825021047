.cadreRose{
    width: 115px;
    height: 5px;
    border-radius: 2px;
    background-color: rgb(238,70,139);
}

.cadreRouge{
    width: 150px;
    height: 5px;
    border-radius: 2px;
    background-color: rgb(241,99,96);
}