.modal-sidebar {
  position: fixed;
  top: 117px;
  right: -100%; /* Hide sidebar initially */
  min-width: 300px; /* Sidebar width */
  max-width: 90%;
  height: 88vh;
  overflow: auto;
  background: #f4f4f4;
  // box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transition: right 0.5s ease;
  z-index: 9999;
  background: #f3f6f9;
}

.modal-open::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(100, 100, 100, 0.3); /* Semi-transparent black background */
  z-index: 500; /* Ensure it's below the sidebar but above other content */
}

.modal-sidebar.open {
  right: 0; /* Show sidebar */
}

.box-shadow-abbove > div {
  top: 0;
  height: 100vh;
  overflow: hidden;
}

.sidebar-header {
  display: flex;;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #efefef;
  box-shadow: 0px 10px 8px -2px #d9e1ea;
  gap: 15px;
}

.sidebar-title {
  width: 100%;
}

.sidebar-title > span {
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
}

.sidebar-content {
  padding: 20px;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: red;
  color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

// Example 2.
@mixin cross($size: 20px, $color: currentColor, $thickness: 1px) {
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  position: relative;
  width: $size;
  height: $size;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: ($size - $thickness) / 2;
    left: 0;
    right: 0;
    height: $thickness;
    background: $color;
    border-radius: $thickness;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  span {
    display: block;
  }

}

.cross-stand-alone-container {
  display: flex;
  position: relative;
  cursor: pointer;
}

.cross-stand-alone {
  @include cross(30px, #585c66, 4px);
}
