.footerButton:hover{
    transform: scale(1.05);
    transition: 0.6s;
    cursor: pointer;
}

footer {
  height: 60px !important;

  & > div {
    height: 60px;
  }
}

.boutonEnregistrementFooter{
    background: linear-gradient(#82BB4B, #279757 );
}

.boutonActionFooter{
    background: #3D4760
}

.vertical-line-footer {
  height: 35px;
  width: 5px;
  background-color: #C5C5C5;
  border-radius: 99px;
}

svg#calculatrice-icon:hover {
  cursor: pointer;
  height: 47px;
  transition: height .3s;
  border-radius: 4px;

  #calculatrice_hover {
    border-radius: 4px;
  }

  #Rectangle_21773 {
    height: 47px;
    transition: height .3s;
  }

  #Rectangle_21774 {
    transform: translate(4px, 20px);
  }

  #Rectangle_21777 {
    transform: translate(16px, 20px);
  }

  #Rectangle_21775 {
    transform: translate(4px, 31px);
  }

  #Rectangle_21776 {
    transform: translate(16px, 31px);
  }

  #Rectangle_21779 {
    transform: translate(28px, 20px);
  }

  #Rectangle_21780 {
    height: 31px;
    width: 11.981px;
    transition: all .3s;
  }

  #Rectangle_21786 {
    width: 2px;
    height: 19px;
    background-color: #8794b9;
    transform: translate(33px, 8px) rotate(90deg);
    transition: tranlsate .5s, height .5s;
  }

  #Rectangle_21787 {
    width: 1px;
    height: 14px;
    background-color: #8794b9;
    transform: translate(33px, 12px) rotate(90deg);
    transition: tranlsate .5s, height .5s;
  }
}
