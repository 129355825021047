.racineBloc{
    display: flex;
    justify-content: flex-start;
    gap: 15px;
    //padding: 5px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.div1{
    width: 32%;
    background-color: #fff;
    border-radius: 30px;
    box-shadow: rgba(105, 95, 151, 0.11) 2px 13px 54px;
}


.div2{
    width: 20%;
    background-color: #fff;
    border-radius: 30px;
    box-shadow: rgba(105, 95, 151, 0.11) 2px 13px 54px;

}

.div3{
    width: 20%;
    background-color: #fff;
    border-radius: 30px;
    box-shadow: rgba(105, 95, 151, 0.11) 2px 13px 54px;
}

.div4 {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  margin-right: 15px;
}
