* {
    font-family: roboto;
}

button.close {
    opacity: 0.8;
    color: white;
}

.center {
    text-align: center;
}

.lien {
    color: #3c4b64;
    text-decoration: none;
}

.lien:hover {
    color: #3c4b64;
    text-decoration: none;
}

//menu simulations
#menu_simulateur .text-muted {
    //page accueil liste simulateur
    height: 34px;
    display: inline-block;
}

.lien .card h4 {
    font-weight: 700;
}

.lien .card {
    font-family: roboto;
}

// Hover dans la liste des simulateurs autonomes
.lien:hover>.card {
    border: none;
    color: white;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, .26) !important;
}

// Quand la souris est sur le PEA
.lienPEA:hover>.card {
    background: rgb(236, 120, 102)
}

// Quand la souris est sur le CT
.lienCT:hover>.card {
    background: rgb(99, 155, 216)
}

// Quand la souris est sur le AV
.lienAV:hover>.card {
    background: rgb(242, 198, 98)
}

// Quand la souris est sur le CAPI
.lienCP:hover>.card {
    background: rgb(101, 191, 127)
}

// Quand la souris est sur le IS
.lienIS:hover>.card {
    background: rgb(124, 111, 219)
}

.lien:hover>.card .text-muted {
    color: white !important;
}

.lien:hover>.card .progress-bar {
    background: white !important;
}

//menu simulations
.intitule_formulaire_simulateurs_autonomes{
    font-size: 15px !important;
    font-weight: 600;
    color: #3C3B3B;
}

.label_title {
    font-size: 15px !important;
    font-weight: 600;
    color: #3C3B3B;
}

.label_title_cession {
    font-size: 13px !important;
    font-weight: bold;
    color: #3c3b3b;
    letter-spacing: 1px;
    padding-left: 0 !important;
}

.btn_font {
    font-size: 15px;
    font-family: roboto;
}

#puce {
    background: #1D51A1;
    height: 15px;
    width: 4px;
    border-radius: 2px;
    margin-right: 10px;
}

.form-group {
    margin-bottom: 5px;
}

#simulationPEA p,
#simulationAV p {
    margin-bottom: 0;
}

.form-group,
.form-group label {
    padding-left: 0;
    color: #393939;
}

#button_action {
    // background: linear-gradient(#F16360,#EF3F75)
    background: #3D4760
}

#saveVert {
    background: linear-gradient(#82BB4B, #279757);
}

/*#saveVert:hover{
    background: transparent !important;
    color: #87c660 !important;
    border: 2px solid #87c660  !important;
}*/

/*#button_action:hover {
    background: transparent !important;
    //color: #ec564a !important;
    color: #3D4760 !important;
    border: 2px solid #3D4760  !important;
}*/

#button_action:hover #icon_button_action {
    color: #3D4760;
}

button.close {
    color: gray !important;
}

button.close:hover {
    color: black !important;
}

#round_icon {
    border-radius: 100%;
    color: white;
    width: 50px;
    height: 50px;
    text-align: center;
    padding-top: 10px;
}

.btn_back {
    color: #9b9999 !important;
}

.btn_back:hover {
    color: #5b5b5b !important;
}

#icon {
    color: #9b9999;
}

#icon:hover {
    // color:#5b5b5b !important;
    color: #000000 !important;
    cursor: pointer;
}

#label_hover {
    padding: 7px;
    display: block;
}

#label_hover:hover {
    color: black !important;
    cursor: pointer;
    background: #e8e8e8 !important;
}

#ajouterClient .form-inline label,
.formulaire .form-inline label,
#simulationCT label,
#simulationAV label,
#simulationPEA label,
#simulationIS label {
    display: unset;
    font-size: 13px;
}

/* remove all p margin-bottom */
#formulairePEA p,
#simulationCT p,
#formulaireAV p,
#simulationIS p {
    margin-bottom: 0;
}

/* Grid */
.react-grid-Cell {
    text-align: center;
}

#icon_header_tableau {
    position: absolute;
    left: 5px;
    top: 5px;
}

#simulationCT .react-grid-HeaderCell:nth-child(9) {
    display: flex !important;
    align-items: center;
}

/* Grid  */

//row hover
.react-grid-Row:not(:last-child):hover .react-grid-Cell {
    background: #ebeaea !important;
}

#simulationCMP .react-grid-Row:last-child:hover .react-grid-Cell {
    background: #ebeaea !important;
}

#simulationPEA .widget-HeaderCell__value,
#simulationCT .widget-HeaderCell__value,
#simulationAV .widget-HeaderCell__value,
#simulationIS .widget-HeaderCell__value {
    font-size: 10px;
    height: 100%;
    vertical-align: middle;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    white-space: normal;
}

#versements_complementaires .react-grid-Grid {
    border: solid 1px rgb(238, 238, 238);
}

#versements_complementaires .react-grid-Header {
    border: solid 1px rgb(238, 238, 238);
    background-color: #fff;
}

//couleur alternance des lignes
#simulationPEA .react-grid-Row--odd .react-grid-Cell,
#simulationCT .react-grid-Row--odd .react-grid-Cell,
#versements_complementaires .react-grid-Row--odd .react-grid-Cell,
#simulationAV .react-grid-Row--odd .react-grid-Cell,
#simulationIS .react-grid-Row--odd .react-grid-Cell,
#simulationCMP .react-grid-Row--odd .react-grid-Cell {
    background: #f8f8f8;
}

#simulationPEA .react-grid-Cell,
#simulationCT .react-grid-Cell,
#simulationAV .react-grid-Cell,
#simulationIS .react-grid-Cell,
#simulationCMP .react-grid-Cell {
    border: none;
}

/* HEADER ROUGE */
#simulationPEA .react-grid-HeaderCell:nth-child(10),
#simulationCT .react-grid-HeaderCell:nth-child(6),
#simulationCT .react-grid-HeaderCell:nth-child(12),
#simulationAV #tableau_1 .react-grid-HeaderCell:nth-child(7),
#simulationAV #tableau_1 .react-grid-HeaderCell:nth-child(12),
#simulationAV #tableau_4 .react-grid-HeaderCell:nth-child(9),
#simulationAV #tableau_4 .react-grid-HeaderCell:nth-child(10),
#simulationIS .react-grid-HeaderCell:nth-child(5),
#simulationIS .react-grid-HeaderCell:nth-child(15) {
    background-color: #F17774; //#1D51A1;
    color: white;
    font-weight: bold;
}

/* HEADER BLUE */
#simulationPEA .react-grid-HeaderCell:nth-child(11),
#simulationCT .react-grid-HeaderCell:nth-child(13),
#simulationAV #tableau_1 .react-grid-HeaderCell:nth-child(3),
#simulationAV #tableau_1 .react-grid-HeaderCell:nth-child(8),
#simulationAV #tableau_1 .react-grid-HeaderCell:nth-child(11),
#simulationAV #tableau_1 .react-grid-HeaderCell:nth-child(13),
#simulationAV #tableau_2 .react-grid-HeaderCell:nth-child(4),
#simulationAV #tableau_2 .react-grid-HeaderCell:nth-child(5),
#simulationAV #tableau_2 .react-grid-HeaderCell:nth-child(10),
#simulationAV #tableau_3 .react-grid-HeaderCell:nth-child(3),
#simulationAV #tableau_3 .react-grid-HeaderCell:nth-child(4),
#simulationAV #tableau_3 .react-grid-HeaderCell:nth-child(7),
#simulationAV #tableau_3 .react-grid-HeaderCell:nth-child(8) {
    background-color: #89BCE8;
    color: white;
    font-weight: bold;
}

/* HEADER ROSE */
#simulationPEA .react-grid-HeaderCell:nth-child(6),
#simulationAV #tableau_1 .react-grid-HeaderCell:nth-child(10),
#simulationCT .react-grid-HeaderCell:nth-child(9),
#simulationIS #tableau_1 .react-grid-HeaderCell:nth-child(11) {
    background-color: #FFF4E8;
}


/* HEADER NOIRE */
#simulationCT .react-grid-HeaderCell:nth-child(7),
#simulationCT .react-grid-HeaderCell:nth-child(8),
#simulationAV #tableau_2 .react-grid-HeaderCell:nth-child(8),
#simulationAV #tableau_2 .react-grid-HeaderCell:nth-child(9) {
    background-color: rgba(0, 0, 0, .4);
    color: white;
    font-weight: bold;
}

/* COLONNE VALORISATION DEBUT ANNEE */
#simulationPEA .react-grid-HeaderCell:nth-child(3),
#simulationCT .react-grid-HeaderCell:nth-child(3) {
    background-color: rgb(29, 74, 121);
    color: white;
}

/* COLONNE ROUGE */
#simulationPEA .react-grid-Cell:nth-child(10),
#simulationCT .react-grid-Cell:nth-child(6),
#simulationCT .react-grid-Cell:nth-child(12),
#simulationAV #tableau_1 .react-grid-Cell:nth-child(7),
#simulationAV #tableau_1 .react-grid-Cell:nth-child(12),
#simulationAV #tableau_4 .react-grid-Cell:nth-child(9),
#simulationAV #tableau_4 .react-grid-Cell:nth-child(10),
#simulationIS .react-grid-Cell:nth-child(5),
#simulationIS .react-grid-Cell:nth-child(15) {
    color: #F17774;
}

/* COLONNE BLUE */
#simulationPEA .react-grid-Cell:nth-child(11),
#simulationCT .react-grid-Cell:nth-child(8),
#simulationCT .react-grid-Cell:nth-child(13),
#simulationAV #tableau_1 .react-grid-Cell:nth-child(3),
#simulationAV #tableau_1 .react-grid-Cell:nth-child(8),
#simulationAV #tableau_1 .react-grid-Cell:nth-child(11),
#simulationAV #tableau_1 .react-grid-Cell:nth-child(13),
#simulationAV #tableau_2 .react-grid-Cell:nth-child(4),
#simulationAV #tableau_2 .react-grid-Cell:nth-child(5),
#simulationAV #tableau_2 .react-grid-Cell:nth-child(10),
#simulationAV #tableau_3 .react-grid-Cell:nth-child(3),
#simulationAV #tableau_3 .react-grid-Cell:nth-child(4),
#simulationAV #tableau_3 .react-grid-Cell:nth-child(7),
#simulationAV #tableau_3 .react-grid-Cell:nth-child(8) {
    color: #2096fb;
}

/*COLONNE ROSE alternance */
/*#simulationPEA .react-grid-Row--odd .react-grid-Cell:nth-child(6),
#simulationCT .react-grid-Row--odd .react-grid-Cell:nth-child(9),
#simulationAV #tableau_1 .react-grid-Row--odd .react-grid-Cell:nth-child(10),
#simulationIS #tableau_1 .react-grid-Row--odd .react-grid-Cell:nth-child(11)
{
    background-color: #f9e9d8;
}*/

/*COLONNE ROSE */
#simulationPEA .react-grid-Cell:nth-child(6),
#simulationCT .react-grid-Cell:nth-child(9),
#simulationAV #tableau_1 .react-grid-Cell:nth-child(10),
#simulationIS #tableau_1 .react-grid-Cell:nth-child(11) {
    background-color: #FFF4E8;
    ;
}

/* row hover sur header rose */
#simulationPEA .react-grid-Row:hover .react-grid-Cell:nth-child(6),
#simulationCT .react-grid-Row:hover .react-grid-Cell:nth-child(9),
#simulationAV #tableau_1 .react-grid-Row:not(:last-child):hover .react-grid-Cell:nth-child(10),
#simulationIS #tableau_1 .react-grid-Row:not(:last-child):hover .react-grid-Cell:nth-child(11) {
    background: #f5e4d3 !important;
}

//derniere ligne du tableau : en grise
#simulationPEA .react-grid-Row:last-child .react-grid-Cell,
#simulationCT .react-grid-Row:last-child .react-grid-Cell,
#simulationAV #tableau_1 .react-grid-Row:last-child .react-grid-Cell,
#simulationAV #tableau_2 .react-grid-Row:last-child .react-grid-Cell,
#simulationAV #tableau_3 .react-grid-Row:last-child .react-grid-Cell,
#simulationAV #tableau_4 .react-grid-Row:last-child .react-grid-Cell,
#simulationIS #tableau_1 .react-grid-Row:last-child .react-grid-Cell {
    color: white;
    background: #797878;
    height: 80px;
}

#simulationIS #tableau_1 .react-grid-Row:last-child .react-grid-Cell:nth-child(14) .crayon{
    display: none;
}

//derniere ligne du tableau : en noir
#simulationPEA .react-grid-Row:last-child .react-grid-Cell:nth-child(3),
#simulationPEA .react-grid-Row:last-child .react-grid-Cell:nth-child(6),
#simulationPEA .react-grid-Row:last-child .react-grid-Cell:nth-child(10),
#simulationPEA .react-grid-Row:last-child .react-grid-Cell:nth-child(11),
#simulationCT .react-grid-Row:last-child .react-grid-Cell:nth-child(3),
#simulationCT .react-grid-Row:last-child .react-grid-Cell:nth-child(7),
#simulationCT .react-grid-Row:last-child .react-grid-Cell:nth-child(8),
#simulationCT .react-grid-Row:last-child .react-grid-Cell:nth-child(9),
#simulationCT .react-grid-Row:last-child .react-grid-Cell:nth-child(12),
#simulationCT .react-grid-Row:last-child .react-grid-Cell:nth-child(13),
#simulationAV #tableau_1 .react-grid-Row:last-child .react-grid-Cell:nth-child(3),
#simulationAV #tableau_1 .react-grid-Row:last-child .react-grid-Cell:nth-child(8),
#simulationAV #tableau_1 .react-grid-Row:last-child .react-grid-Cell:nth-child(11),
#simulationAV #tableau_1 .react-grid-Row:last-child .react-grid-Cell:nth-child(13),
#simulationAV #tableau_2 .react-grid-Row:last-child .react-grid-Cell:nth-child(4),
#simulationAV #tableau_2 .react-grid-Row:last-child .react-grid-Cell:nth-child(5),
#simulationAV #tableau_2 .react-grid-Row:last-child .react-grid-Cell:nth-child(8),
#simulationAV #tableau_2 .react-grid-Row:last-child .react-grid-Cell:nth-child(9),
#simulationAV #tableau_2 .react-grid-Row:last-child .react-grid-Cell:nth-child(10),
#simulationAV #tableau_3 .react-grid-Row:last-child .react-grid-Cell:nth-child(3),
#simulationAV #tableau_3 .react-grid-Row:last-child .react-grid-Cell:nth-child(4),
#simulationAV #tableau_3 .react-grid-Row:last-child .react-grid-Cell:nth-child(7),
#simulationAV #tableau_3 .react-grid-Row:last-child .react-grid-Cell:nth-child(8),
#simulationAV #tableau_4 .react-grid-Row:last-child .react-grid-Cell:nth-child(9),
#simulationAV #tableau_4 .react-grid-Row:last-child .react-grid-Cell:nth-child(10) {
    color: white;
    background: #535353 !important;
    height: 80px;
    box-sizing: content-box;
    border: 1px solid #949494;
    padding: 0;
}



#simulationAV #tableau_1 .react-grid-Canvas:nth-child(1) .react-grid-Row:last-child .react-grid-Cell:nth-child(10) svg,
#simulationPEA .react-grid-Canvas:nth-child(1) .react-grid-Row:last-child .react-grid-Cell:nth-child(6) svg,
#simulationCT .react-grid-Canvas:nth-child(1) .react-grid-Row:last-child .react-grid-Cell:nth-child(9) svg,
#simulationIS #tableau_1 .react-grid-Canvas:nth-child(1) .react-grid-Row:last-child .react-grid-Cell:nth-child(11) svg {
    display: none;
}


#versements_complementaires .react-grid-HeaderCell:nth-child(3) div,
#versements_complementaires .react-grid-HeaderCell:nth-child(5) div {
    padding-left: 5px;
    padding-right: 5px;
}


#versements_complementaires .react-grid-HeaderCell:nth-child(1),
#versements_complementaires .react-grid-HeaderCell:nth-child(2) {
    font-size: 12px;
}

#versements_complementaires .react-grid-HeaderCell:nth-child(3),
#versements_complementaires .react-grid-HeaderCell:nth-child(4),
#versements_complementaires .react-grid-HeaderCell:nth-child(5) {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 12px;
}

#versements_complementaires .react-grid-HeaderCell {
    padding-right: 0;
    padding-left: 0;
}

#versements_complementaires .react-grid-HeaderCell:nth-child(3)>div,
#versements_complementaires .react-grid-HeaderCell:nth-child(4)>div {
    height: 100%;
    vertical-align: middle;
}


#simulationPEA .react-grid-HeaderCell:nth-child(11),
#simulationCT .react-grid-HeaderCell:nth-child(8),
#simulationCT .react-grid-HeaderCell:nth-child(13) {
    background-color: #89BCE8;
    color: white;
    font-weight: bold;
}

#simulationCMP .react-grid-HeaderRow,
#simulationIS .react-grid-HeaderRow,
#simulationAV .react-grid-HeaderRow {
    overflow: hidden !important;
}

/* Donation */
#donationClient tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
}

#donationClient.table th,
#donationClient.table td {
    padding: 8px 10px;
}

#donationClient.table th {
    background-color: #eaeaea;
}

//separateur
hr.dashed {
    border-top: 1px dashed #d2d1d1;
}

#label_remove_enfant:hover>label {
    color: rgb(218, 4, 4);
}

/* Date picker */
.DayPicker {
    position: absolute;
    background-color: white;
    z-index: 2;
    top: -5px;
}

/* Header */
.c-header-nav-link {
    height: 54px;
}

.c-header-nav-link.active {
    color: rgb(30, 97, 162) !important;
    font-weight: bold;
    height: 54px;
}

// Fil d'Ariane
.c-subheader {
    background-color: rgba(0, 0, 0, 0.05) !important;
}

/*.c-subheader,
.c-subheader-nav {
    min-height: 35px !important;
}*/

/* Tableau */
td {
    vertical-align: middle !important;
}

/*form_ct/ form_av*/
.formulaire .form-group label {
    padding-left: 14px;
}

#form_ct .react-grid-Grid,
#form_av .react-grid-Grid {
    text-align: center;
    border: none;
}

#versements_complementaires .react-grid-HeaderCell {
    border: none;
    white-space: normal;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    display: flex !important;
}

#versements_complementaires .react-grid-Cell {
    border: none;
    text-align: center;
}

/*form_ct / form_av*/

/*form_av*/
#versements_complementaires .react-grid-HeaderCell {
    background: #f1f1f2;
    border-bottom: 1px solid rgb(187, 187, 187);
}

//alternance du couleur

#form_av .react-grid-HeaderCell:nth-child(3),
#form_av .react-grid-HeaderCell:nth-child(5),
#form_ct .react-grid-HeaderCell:nth-child(3),
#form_ct .react-grid-HeaderCell:nth-child(4),
#form_is .react-grid-HeaderCell:nth-child(3),
#form_is .react-grid-HeaderCell:nth-child(4) {
    background: #d0d0d0;
    border-right: 1px solid white;
    text-align: center;
}

#form_av .react-grid-Row--odd .react-grid-Cell:nth-child(3),
#form_av .react-grid-Row--odd .react-grid-Cell:nth-child(5),
#form_ct .react-grid-Row--odd .react-grid-Cell:nth-child(3),
#form_ct .react-grid-Row--odd .react-grid-Cell:nth-child(4),
#form_is .react-grid-Row--odd .react-grid-Cell:nth-child(3),
#form_is .react-grid-Row--odd .react-grid-Cell:nth-child(4) {
    background: #f0f0f0;
    border-right: 1px solid white;
}

#form_av .react-grid-Row--even .react-grid-Cell:nth-child(3),
#form_av .react-grid-Row--even .react-grid-Cell:nth-child(5),
#form_ct .react-grid-Row--even .react-grid-Cell:nth-child(3),
#form_ct .react-grid-Row--even .react-grid-Cell:nth-child(4),
#form_is .react-grid-Row--even .react-grid-Cell:nth-child(3),
#form_is .react-grid-Row--even .react-grid-Cell:nth-child(4) {
    background: #f8f8f8;
    border-right: 1px solid white;
}

/*form_av*/

// #form_ct .react-grid-Row--odd .react-grid-Cell
// {
//     background: #f8f8f8;
// }
// #form_ct .react-grid-Row--even .react-grid-Cell
// {
//     background: white;
// }

#form_ct p {
    margin-bottom: 0;
}

/*form_ct*/

.react-grid-Canvas {
    overflow-y: hidden !important;
    height: auto !important;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #f4f5f7;
}

.form-control:focus {
    box-shadow: 0px 0px 2px 1px #1D51A1;
}

/* SideBar */
.c-sidebar .c-sidebar-nav-dropdown.c-show {
    background-color: transparent;
}

// Menu icône au SURVOL
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover

/*, .c-side-bar-nav-item .c-side-bar-nav-link*/
    {
    background-color: #FFF;
}

// Icône et Investissements financiers en BLEU au SURVOL
.c-sidebar .c-sidebar-nav-link:hover {
    background-color: #F3FCFF;
    color: #1990C1;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    font-weight: bold;
    // border-top-right-radius: 15px;
}

// Partie restante du border-radius au SURVOL
.c-sidebar.c-sidebar-minimized .c-sidebar-nav>.c-sidebar-nav-dropdown:hover {
    background: #1990C1;
    border-top-right-radius: 15px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

// Mise en couleur blanche des items de MENU déroulant (Par défaut)
.c-sidebar-minimized .c-sidebar-nav-dropdown-items {
    background: #D8F5FF !important;
    border-bottom-right-radius: 15px;
    //color: #32396c !important
}

/*1D51A1*/
.c-sidebar-minimized li.c-sidebar-nav-dropdown ul.c-sidebar-nav-dropdown-items .c-sidebar-nav-item:hover .c-sidebar-nav-link {
    background: #1990C1 !important;
    color: white !important;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

// Item du Menu déroulant
.c-sidebar .c-sidebar-nav-link {
    color: #1D51A1 //#32396c !important;
}

.c-sidebar .c-sidebar-nav-link:hover .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
    color: #1990C1 !important;
}

//color icon sidebar
.c-sidebar .c-sidebar-nav-link svg {
    color: #FFFFFF !important;
}


.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
    color: #fff !important;
}

.c-sidebar-minimized .c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
    color: #1990C1 !important;
}

.c-sidebar .c-sidebar-nav-link:hover svg {
    color: white
}

/* wrapper */
/*.c-wrapper:not(.c-wrapper-fluid) .c-body {
    background: rgb(243, 243, 243);
}*/

.round {
    border-radius: 50%;
    // box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.37);
}

.btn_export:hover svg {
    color: #fcdbd8 !important;
}

.btn_export:hover {
    background: #403f3f !important;
}

.btn_export.dropdown-toggle::after {
    display: none !important;
}

#simulationAV #colonne_header,
#simulationIS #colonne_header {
    text-align: center;
    width: 98.1%;
    margin-bottom: 0;
    border: none;
    margin-top: 10px;
}

#simulationAV #colonne_header td,
#simulationIS #colonne_header td {
    width: 10%;
    padding: 6px;
    font-weight: 600;
}

#simulationAV #titre_separateur_header,
#simulationIS #titre_separateur_header {
    margin-right: 0;
    position: relative;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

#simulationAV #titre_separateur_header span,
#simulationIS #titre_separateur_header span {
    border: 1px solid #d5d5d5;
    border-bottom: 0;
    text-align: center;
    padding: 6px;
    color: #515050;
    ;
    background: #f7f7f7;
}

.card-header {
    font-size: 1.1em;
    font-weight: 600;
    word-spacing: 2px;
    letter-spacing: .1rem;
    color: #393939;
}

//couleur principale
.text_base_color {
    color: #1D51A1;
}

.base_background_color {
    background-color: #1D51A1;
}


.base_color {
    //background: #ec564a;
    background: #1D51A1;
}

/*.c-wrapper:not(.c-wrapper-fluid) .c-body {
    // background: #f3f3f3;
    background: #f8f8f8;
}*/

/* HEADER */
.c-header.c-header-dark {
    background: #393939;
}

.c-header-nav-link:hover {
    // background: #1D51A1 ;//#222;
    border-bottom: 2px solid #1D51A1;
    // border-left: 3px solid #ffffff5e;
    // border-right: 3px solid #ffffff5e;
    color: #1D51A1 !important;
}

.c-header-nav-link.active {
    // background: #1D51A1;//#222;
    color: #1D51A1 !important;
    border-bottom: 2px solid #1D51A1;

}

/*.c-header .bloc-fil-ariane a {
    color: #9C9C9C;
    font-size: 11px;
}*/

/*.c-header a:hover {
    color: #809FB8;
    font-weight: bold;
}*/

.bouton-plateforme:hover{
    cursor: pointer;
    transform: scale(1.05);
    transition: 0.6s
}

.c-header-nav-link {
    color: rgba(255, 255, 255, 0.75);
    margin-left: 40px !important;
    padding: 0 !important;

}

// .page-item.active .page-link {
//     background-color: rgb(33,62,120);
//     border-color: rgb(33,62,120);
//     padding-left: 7px;
//     padding-right: 7px;
// }

.active.page-item .page-link {
    background: linear-gradient(180deg, #17A3CA, #1B6AAE);
    border: none !important;
    font-size: 14px !important;
    margin-top: 0px !important;
    padding-left: 13px;
    padding-right: 14px;
    padding-top: 7px;
    padding-bottom: 7px;
    font-weight: bold;

    // border-color: #bf463b !important;
}

.dropdown-menu a:hover {
    color: #fff;
    background: #393939;
}

.page-item .page-link {
    margin-left: 7px;
    margin-right: 7px;
    border: none !important;
    font-size: 14px;
    margin-top: 2.4px;
    color: grey;
    border-radius: 20px;
}

.page-item:hover .page-link {
    color: #1D51A1;
    background: none;
}

//fleche en noir, indiquant le dropdown menu
.c-sidebar-nav-dropdown:not(:active) .c-sidebar-nav-dropdown-toggle::after,
.c-sidebar-nav-dropdown.active.c-show .c-sidebar-nav-dropdown-toggle::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='white' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}

.c-sidebar .c-sidebar-nav-dropdown-toggle:hover::after,
.c-sidebar-nav-dropdown.active:not(.c-show) .c-sidebar-nav-dropdown-toggle::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='rgb(25,144,193)' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}

@font-face {
    font-family: roboto;
    src: url('../assets/fonts/Roboto-Regular.ttf');
    font-weight: normal;
}

@font-face {
    font-family: roboto;
    src: url('../assets/fonts/Roboto-Bold.ttf');
    font-weight: bold;
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
.card-header {
    font-family: roboto;
}

#btn_compte {
    color: white;
    border-radius: 50px;
}

#btn_compte:hover {
    background: #1D51A1;
}

#afficher_toutes {
    font-size: 0.8em;
    letter-spacing: 0;
    color: grey;
}

#afficher_toutes:hover {
    color: blue;
}

.icon_reload {
    animation: rotate 2s linear infinite;
}

//gestion de masquage colonne 7,15 du tableau is

.hide_colonne {
    padding: 0 !important;
}

.hide_colonne div div,
.hide_colonne .icon {
    display: none !important;
}

.hide_colonne .icon_header_open {
    display: block;
}

.icon_header_open {
    display: none;
}

// .header_IS_border_left{
//     border-left: 1px solid #ddd;
// }
// .header_IS_border_left_none{
//     border-left: 0px solid #ddd;
// }
#simulationIS .react-grid-HeaderCell:nth-child(6) {
    border-right: 0px solid #a1a1a1;
}

#simulationIS .react-grid-HeaderCell:nth-child(7) {
    // background:grey !important;
    border-left: 1px solid #ddd;
}

#simulationIS .react-grid-HeaderCell:nth-child(7):hover{
    border: 1px solid #b6b6b6;
}

//comparaisons
.btn_link_onglet.c-header-nav-link.active {
    background: #222222 !important;
}

.btn_link_onglet,
.btn_link_onglet a {
    background: #393939 !important;
}

.btn_link_onglet:hover,
.btn_link_onglet:hover a {
    background: black !important;
}

.bg_input_label_cmp {
    // background: #ffedeb;
    background: #dfe1e4;
    padding-top: 7px !important;
    padding-bottom: 7px !important;
}

.bg_input_cmp {
    background: #f9f3f3;
}

select option:disabled {
    color: silver !important;
}

#link_onglet {
    z-index: 100;
    margin: 1em;
}

#btn_add {
    cursor: pointer;
}

#btn_add span {
    background: #1D51A1;
    // border: 1px solid silver;
    border-radius: 50%;
    width: 38px;
    height: 38px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    transition: background 0.1s;
}

#btn_add:hover span {
    background: #6373b1;
}

#btn_add:hover {
    color: black;
}

.delete_item:hover,
.close_calendar:hover {
    color: #6373b1;
}

.close_calendar {
    position: absolute;
    right: -11px;
    top: -11px;
    border: 1px solid silver;
    border-radius: 50%;
    padding: 4px;
}

#link_onglet .c-header-nav-link {
    background-color: grey;
    color: rgba(255, 255, 255, 0.75);
    border-radius: 3px;
}

#link_onglet .c-header-nav-link:hover,
#link_onglet .c-header-nav-link.active {
    background-color: #1D51A1;
    //background-color: #1D51A1;
    color: rgba(255, 255, 255, 0.75);
}

#link_onglet .c-header-nav-item {
    text-align: center;
    margin: 2px;
}

#link_onglet .c-header-nav-link {
    padding: 10px 10px !important;
}

#link_onglet .c-header-nav-item a {
    font-weight: normal;
}

#formulaireCMP .react-grid-Main select {
    text-align: center;
}

#formulaireCMP .react-grid-HeaderRow {
    overflow-x: visible !important;
}

#formulaireCMP #form_sur_mesure .form-group span {
    position: absolute;
    right: 0;
}

#formulaireCMP #form_sur_mesure_item {
    // border: 1px solid #dbdbdb;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-radius: 10px;
    margin-bottom: 15px;
}

#formulaireCMP #form_sur_mesure_item label {
    padding-left: 0.25rem !important;
    margin-left: 0.25rem !important;
}

// #formulaireCMP #versements_complementaires .react-grid-HeaderCell:nth-child(3){
//     display: contents;
//     text-align: center;
//     white-space: initial !important;
// }

#simulationCMP #tab1 .react-grid-HeaderCell,
#simulationCMP #tab1 .react-grid-HeaderRow {
    height: 80px !important;
}

#simulationCMP #tab1 .react-grid-HeaderRow {
    left: 0px !important
}

#simulationCMP #tab1 .react-grid-Header {
    height: 80px !important;
    left: 9px;
    width: 350px
}

// #colTab{
//     min-width: 425px !important;
// }

#simulationCMP #tab2 .react-grid-HeaderCell,
#simulationCMP #tab2 .react-grid-HeaderRow,
#simulationCMP #tab2 .react-grid-Header {
    height: 45px !important;
    margin-left: 8px;
    // overflow: hidden;
}

#simulationCMP .react-grid-Cell {
    // border-top:1px solid #ffffff41;
    border-bottom: 1px solid #ffffff65;
}

#simulationCMP #tab1 .react-grid-Viewport {
    top: 80px !important;
}

#simulationCMP #tab1 .react-grid-Canvas {
    left: 8px !important
}

#simulationCMP #tab1 .react-grid-Grid {
    border: none !important
}

#simulationCMP #tab1 .react-grid-Main {
    outline: none !important
}

#simulationCMP #tab2 {
    outline: none !important
}

#simulationCMP #tab2 .react-grid-Canvas {
    left: 8px !important
}

#simulationCMP #tab2 {
    border: none !important
}

#simulationCMP #tab2 .react-grid-Main {
    outline: none !important
}

#myTab .react-grid-Viewport {
    top: 35px !important;
}

#simulationCMP #tab2 {
    border: 1px solid #ddd;
    outline: 1px solid #e7eaec;
}

#simulationCMP .react-grid-HeaderCell {
    white-space: normal !important;
}

#simulationCMP #tab2 .react-grid-Viewport {
    top: 45px !important;
}

#simulationCMP #tab2 #choix span {
    display: inline-block;
    text-align: center;
    height: 35px;
}

#simulationCMP #tab2 #choix .col_choix {
    flex: 0 10 100%;
}

#simulationCMP #tab2 #type {
    text-align: center;
    background: grey;
    color: white;
    border-bottom: 1px solid silver;
}

#simulationCMP .header_row_multiple div,
#simulationCMP #tab2 #type {
    align-items: center;
    justify-content: center;
}

#simulationCMP .header_row_multiple .header_1,
#simulationCMP .header_row_multiple .header_2,
#simulationCMP .header_row_multiple .header_3,
#simulationCMP .header_row_multiple #type {
    display: flex;
}

#simulationCMP #voir_detail {
    font-style: italic;
    color: #1D51A1;
    width: 100%;
    display: inline-block;
    font-size: 13px;
}

#simulationCMP #voir_detail:hover {
    color: blue;
}

#simulationCMP .header {
    background: #f8f8f8;
    color: #585C66;
}

#simulationCMP .header_1 {
    border-bottom: 1px solid #d2cfcf;
    margin-left: 2px;
}

#simulationCMP .header:nth-child(2) {
    color: red;
}

#simulationCMP #tab2 .react-grid-Main {
    outline: none;
}

#simulationCMP #tab2 .react-grid-Grid {
    border: none;
}

#simulationCMP .react-grid-HeaderCell {
    padding: 0;
    border: none;
}

.header_row_multiple {
    font-weight: normal;
    flex-direction: row;
}

#tab2 .header_row_multiple {
    flex-direction: row;
    display: flex;
}

.header_row_multiple div,
#simulationCMP #tab2 #choix {
    height: 35px;
}

.header_row_multiple .header_2,
#simulationCMP #tab2 #type {
    height: 40px;
}

.resultat_FI .header_row_multiple .header_2 {
    height: 35px;
}

// .header_row_multiple .header_3{
//     height: 45px;
// }
.header_row_multiple .bg {
    background: #797878;
    // border-right: 1px solid #ddd;
    border-bottom: 1px solid #a8a8a8;
    color: white;
}

.header_row_multiple .border_right {
    border-right: 1px solid #a8a8a8;
}

.header_row_multiple #titre_col {
    border-width: 0px;
    height: 45px;
}

#btn_onglet {
    padding: 10px;
    background: #393939;
    color: white;
    border-radius: 4px;
}

#information {
    padding-top: 10px;
    border-radius: 3px;
    border: 1px solid #e6e4e4;
    margin-left: 10;
    color: #a6a6a6;
    background: rgb(242, 242, 242);
}

//accueil comparaisons
/*#simulation_item .card.top{
    background: linear-gradient(#e3dede12 , white );
    border-top: 2px solid #b9b8b8;
}
#simulation_item .card.bottom{
    background: linear-gradient(white,#e3dede12 );
    border-bottom: 2px solid #b9b8b8;
}*/
.ligne {
    // height: 3px;
    // background: #fff;
    // margin-top: 30px;
    // position: absolute;
    // border: 1px solid #c1bfbf;
    height: 3px;
    margin-top: 30px;
    position: absolute;
    border-top: 1px solid #c1bfbf;
    left: 0px;
    border-bottom: 1px solid #c1bfbf;
    border-right: 1px solid #c1bfbf;
}

@media only screen and (min-width: 1400px) {

    #proposition_accueil {

        // margin-top: 8%;
        .party_haut {
            margin-bottom: 7%;
        }
    }
}

#cmp_accueil .round,
#proposition_accueil .round {
    width: 40px;
    height: 40px;
    background: white;
    color: #1D51A1; //#ec564a;
    border: 2px solid #f7a8a1;
}

#etapes li {
    font-size: 16px;
}

#etapes li span {
    // height: 100%;,
    position: absolute;
    top: -1px;
    left: 100%;
    content: '';
    border: 36px solid transparent;
    border-top-width: 42px;
    border-bottom-width: 48px;
    border-left-color: transparent;
    border-left-style: solid;
    z-index: 1;
    // -webkit-transform: translateX(4px);
    // -moz-transform: translateX(4px);
    // -ms-transform: translateX(4px);
    // -o-transform: translateX(4px);
    // transform: translateX(4px);
    border-left-color: #e6e4e4;
}

#etapes #arrow_right #border {
    border-left-color: #fff;
    top: -42px;
    left: -37px;
}

#etapes #arrow_left {
    z-index: 0;
    left: 0%;
    border-left-color: #e6e4e4;
}

#etapes #arrow_left #border {
    border-left-color: #f8f8f8;
    top: -42px;
    left: -37px;
}

#etapes li {
    position: relative;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4px 12px 4px 0;
    background-color: #fff;
    border-color: #96c03d;
    float: left;
    padding: 10px 20px 10px 50px;
    text-align: center;
    color: grey;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    // border-bottom-right-radius: 4px;
    // border-top-right-radius: 3px;
    border: 1px solid #e6e4e4;
}

#etapes li::marker {
    content: "";
}

//datepicker
.DayPicker-Day {
    border-radius: 0% !important;
}

.DayPicker-Day--today {
    color: #1D51A1 //#EC564A;
}

.DayPicker {
    border: 1px solid #e3e0e0;
    border-radius: 3px;
}

.DayPicker-Caption {
    background: #4d4d4d !important;
    color: white !important;
    border-radius: 3px !important;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background: #1D51A1 !important //#EC564A!important;
}

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: #e1e1e1 !important;
    color: black !important;
}

//proposition FI
#bouton {
    background: #808080;
    color: white;
}

#bouton:hover {
    background: #585858;
}

.btn.selected {
    background: #1D51A1 !important;
}

#modal_form2 .modal-header {
    background: #b7b7b7;
    color: white;
}

/* simulation pea: durée de projection : ligne 0->5 */
#simulationPEA .react-grid-Row:nth-child(1) .react-grid-Cell:nth-child(1),
#simulationPEA .react-grid-Row:nth-child(2) .react-grid-Cell:nth-child(1),
#simulationPEA .react-grid-Row:nth-child(3) .react-grid-Cell:nth-child(1),
#simulationPEA .react-grid-Row:nth-child(4) .react-grid-Cell:nth-child(1),
#simulationPEA .react-grid-Row:nth-child(5) .react-grid-Cell:nth-child(1),
#simulationPEA .react-grid-Row:nth-child(1) .react-grid-Cell:nth-child(6),
#simulationPEA .react-grid-Row:nth-child(2) .react-grid-Cell:nth-child(6),
#simulationPEA .react-grid-Row:nth-child(3) .react-grid-Cell:nth-child(6),
#simulationPEA .react-grid-Row:nth-child(4) .react-grid-Cell:nth-child(6),
#simulationPEA .react-grid-Row:nth-child(5) .react-grid-Cell:nth-child(6) {
    color: #F17774;
}


.simulation .modal-lg {
    max-width: 650px !important;
}

// Link
li.disabled {
    pointer-events: none;
    opacity: 0.3;
}

#ft iframe {
    height: 100%;
}

//width trableaux transmission (pour les larges écrans sont en base
.width_tab_1_1 {
    // pour 1 simulateur
    min-width: 335px !important;
    flex-grow: 0 !important;
}

.width_tab_1_2 {
    // pour 2 simulateurs
    min-width: 480px !important;
    flex-grow: 0 !important;
}

.width_tab_1_3 {
    // pour 3 simulateurs
    min-width: 600px !important;
    flex-grow: 0 !important;
}


.width_tab_2_1 {
    min-width: 320px !important;
    flex-grow: 0 !important;
}

.width_tab_2_2 {
    min-width: 140px !important;
    flex-grow: 0 !important;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

#simulationCMP #tab2 .react-grid-Container {
    margin-top: 35px;
}

#simulationCMP .react-grid-Header {
    // box-shadow: 0px 1px 2px 0px #ddd !important;
    background-color: #fff !important;
    box-shadow: 0 0 4px 0 #fff !important
}

#simuu {
    display: flex;
}

@media screen and (max-width:1400px) {

    .title_pf {
        font-size: 13px;
    }
}

@media screen and (min-width:700px) and (max-width:1200px) {
    #simuu {
        width: 30%;
    }

}

.titre_tableau_graph {
    font-size: 14px;
    font-weight: bold;
    color: #7F869A;
    margin-bottom: 20px !important;
}

.titre_tableau_graph_rlt-fi {
    font-size: 14px;
    font-weight: bold;
    color: #7F869A;
    margin-bottom: 3px !important;
}

.fixed {
    position: fixed !important;
    z-index: 10;
    top: 50px !important;
}

.fixed_2 {
    //PEA,CT,AV,CAPI
    position: fixed !important;
    z-index: 10;
    top: 115px !important;
}

.fixed_3 {
    // IS
    position: fixed !important;
    z-index: 10;
    top: 115px !important;
    margin-left: 87px;
}

.fixed_4 {
    //comparateur transmission tab1
    // position: fixed !important;
    z-index: 10;
    // margin-top:107px !important; //CHANGE LA POSITION DE TOUS LES TAB VERS AXE Y
    // margin-left:71px !important;
    padding: 0;
}

.fixed_5 {
    //comparateur transmission tab2
    // position: fixed !important;
    z-index: 10;
    // margin-top:107px !important; //CHANGE LA  POSITION ligne fiscalite total
    margin-left: 87px;
}

.fixed_6 {
    //comparateur financiaire
    position: fixed !important;
    z-index: 10;
    top: 148px !important;
}

.max_width_none {
    max-width: none !important;
    padding: 0;

}

.react-grid-Header .max_width_none {
    margin-left: 8px !important;
}

#cacher_les_bords {
    display: none;
}

#cacher_les_bords_L,
#cacher_les_bords_R {
    position: fixed;
    height: 100px;
    background: #f7f7f7;
    top: 90px;
    z-index: 100;
}

#cacher_les_bords_L {
    left: 32px;
    width: 40px;
    border-right: 1px solid #d8dbe0;
}

#cacher_les_bords_R {
    width: 16px;
    right: 0;
    border-left: 1px solid #d8dbe0;
}

/* FORMULALAIRE (hypothèse modifiable) AV */
@media (min-width: 992px) {
    #parametre_simulation_av .col-lg-7 {
        max-width: 56%;
    }

    #parametre_simulation_av .col-lg-2 {
        max-width: 19%;
        flex: 0 0 19%;
    }
}

@media (min-width: 768px) {
    #parametre_simulation_av .col-md-7 {
        max-width: 56%;
    }

    #parametre_simulation_av .col-md-2 {
        max-width: 19%;
        flex: 0 0 19%;
    }
}

/* FORMULALAIRE (hypothèse modifiable) AV */

.simulation .modal-lg,
.proposition_fi .modal-lg {
    max-width: 650px !important;
}

.proposition_fi .modal-lg label,
.comparaison .modal-lg label {
    display: unset;
}

// Link
li.disabled {
    pointer-events: none;
    opacity: 0.3;
}

#ft iframe {
    height: 100%;
}

//width trableaux transmission (pour les larges écrans sont en base
.width_tab_1_1 {
    // pour 1 simulateur
    min-width: 310px !important;
    flex-grow: 0 !important;
}

.width_tab_1_2 {
    // pour 2 simulateurs
    min-width: 450px !important;
    flex-grow: 0 !important;
}

.width_tab_1_3 {
    // pour 3 simulateurs
    min-width: 580px !important;
    flex-grow: 0 !important;
}


@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

/* Bloc de formulaire pour les simulateurs autonomes */
@media (max-width: 540px){
    .simulateur-autonome-with-left-padding{
        padding-left: 15px
    }
}

@media (min-width: 540px){
    .simulateur-autonome-with-left-padding{
        padding-left: 50px
    }
}

/* FORMULALAIRE (hypothèse modifiable) AV */
@media (min-width: 992px) {
    #parametre_simulation_av .col-lg-7 {
        max-width: 56%;
    }

    #parametre_simulation_av .col-lg-2 {
        max-width: 19%;
        flex: 0 0 19%;
    }
}

@media (min-width: 768px) {
    #parametre_simulation_av .col-md-7 {
        max-width: 56%;
    }

    #parametre_simulation_av .col-md-2 {
        max-width: 19%;
        flex: 0 0 19%;
    }
}

/* FORMULALAIRE (hypothèse modifiable) AV */

// @media only screen and (min-width: 1400px) {
//     #cmp_accueil{
//         margin-top: 40px;
//     }

//     .block_objectif{
//         margin-bottom: 20px;
//         margin-top: 20px;
//     }

//     .block_investi{
//         margin-bottom: 0;
//         margin-top: 20px;
//         .mt-2{
//             margin-bottom: 25px;
//         }
//     }

//     .block_troisEtp{
//         margin-top: 20px;
//     }
// }

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    #cmp_accueil h1 {
        font-size: 1.2rem !important;
    }

    #cmp_accueil h4,
    #formulaireCMP h4,
    .simulation h4,
    .simulation h1,
    .formulaire h1 {
        font-size: 92% !important;
    }

    #cmp_accueil #etapes .col-md-3 {
        width: 88% !important;
    }

    .formulaire .form-group span {
        position: absolute;
        left: 97%;
        bottom: 8px;
    }

    #formulaireCMP #form_sur_mesure .form-group span,
    #formulaireCMP #form_benificiaire .form-group span {
        right: 0;
        left: auto;
        bottom: auto;
    }

    .formulaire .form-group .mr-2 {
        margin-right: 0 !important;
    }

    .formulaire .card-header,
    .simulation .card-header {
        font-size: 100% !important;
        word-spacing: 0px !important;
        letter-spacing: 0rem !important;
    }

    .card_tableau {
        padding: 0 !important;
    }

    .card_tableau .col {
        padding-left: 2rem !important;
    }

    .header_row_multiple div,
    #versements_complementaires .react-grid-HeaderCell,
    #formulaireCMP #benificiaire_num {
        font-size: 11px !important;
    }

    #versements_complementaires .c-icon {
        right: 0 !important;
    }

    #information {
        font-size: 13px;
    }

    #formulaireCMP #type_simulation {
        display: block !important;
    }

    .formulaire .col-xl-12 {
        padding: 0 !important;
    }

    #button_action {
        font-size: 12px !important;
        margin-bottom: 5px;
        margin-left: 0px !important;
    }

    #button_action::after {
        display: none !important;
        color: green;
    }

    .formulaire #label_retrait_net {
        margin-right: 1.8rem !important;
    }

    .formulaire button,
    .simulation button {
        font-size: 13px;
        padding: 4px;
    }

    .formulaire button .c-icon,
    .simulation button .c-icon {
        margin-right: 0 !important;
    }

    .label_title {
        font-size: 15px !important;
    }

    .simulation #btn_fiche_thematique {
        margin-top: 10px;
        width: 100%;
    }

    //AV
    #form_av_fond_supportUC span {
        left: 2% !important;
        bottom: 0 !important;
    }

    #form_av_fond_supportUC .ml-1 {
        margin-left: 5% !important;
    }

    .formulaire #assiette_frais_uc {
        margin-left: 42% !important;
    }

    #etapes #arrow_left {
        display: none;
    }
}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    #cmp_accueil #etapes .col-md-3 {
        max-width: 30%;
        // font-size: 13px;
    }

    // .simulation h1{
    //     font-size: 1.2rem !important;
    // }
    .col-lg-2 {
        min-width: 170px;
        max-width: 170px
    }
}

@media only screen and (max-width: 768px) {

    #simulationCMP #versements_complementaires .react-grid-HeaderCell,
    #simulationCMP .header_row_multiple div,
    #simulationCMP #tab2 div {
        font-size: 11px !important;
    }

    #simulationAV .justify-content-between {
        justify-content: flex-start !important;
    }

}


@media only screen and (max-width: 1200px) {
    #cmp_accueil #simulation_item .h4 {
        font-size: 1rem !important;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    #formulaireFI .modal-dialog {
        max-width: 60%;
    }

    .transmission #tab2 .col-lg-7 {
        max-width: 52.6%;
    }

    .col-lg-2 {
        min-width: 170px;
        max-width: 170px
    }

    #labeLg {
        padding: 0 0 0 50px;
    }

    #labeLgOAC {
        padding: 0 0 0 22px;
    }

    #labeLgOAC.col-lg-4 {
        min-width: 384px;
        max-width: 384px;
    }

    #labeldentiteAct {
        padding: 0;
    }

    #labeldentiteAct.col-lg-4 {
        min-width: 270px;
        max-width: 270px;
    }

    #labeLgOAC {
        padding: 0 0 0 22px;
    }

    #labeLgOAC.col-lg-4 {
        min-width: 384px;
        max-width: 384px;
    }

    #labeldentiteAct {
        padding: 0;
    }

    #labeldentiteAct.col-lg-4 {
        min-width: 270px;
        max-width: 270px;
    }

    #labeLgType.col-lg-4 {
        min-width: 317px;
        max-width: 317px;
    }


    #labeLg.col-lg-4,
    .label_title.col-lg-4 {
        min-width: 367px;
        max-width: 367px;
    }

    #labeLgSucc.col-lg-4 {
        min-width: 400px;
    }

    // .label_title_cession.col-lg-4

    .label_title_cession.labelSpace {
        min-width: 425px;
        max-width: 425px;
    }

    .label_title_montant_retrait {
        min-width: 257px;
        max-width: 257px;
    }

}

@media only screen and (min-width: 992px) {
    .transmission #tab2 .col-lg-7 {
        max-width: 52.6%;
    }

    .transmission #tab2 .col-lg-5 {
        max-width: 37.6%;
    }

    // .fixed_4{
    //     // top:65px !important;
    // }
    // .fixed_5{
    //     // top:100px !important;
    // }
    .fixed_6 {
        top: 155px !important;
    }

    .width_tab_2_1.fixed_4 {
        min-width: 320px !important;
        width: 180px !important;
        margin-left: 0px !important;
    }

    .width_tab_2_2.fixed_4 {
        min-width: auto !important;
        width: 138px !important;
        margin-left: 0px !important;
    }

    .col-lg-2 {
        min-width: 170px;
        max-width: 170px
    }
}

@media only screen and (min-width: 1400px) {

    //width trableaux transmission
    .width_tab_1_1 {
        min-width: 400px !important;
        flex-grow: 0 !important;
    }

    .width_tab_1_2 {
        min-width: 550px !important;
        flex-grow: 0 !important;
    }

    .width_tab_1_3 {
        min-width: 730px !important;
        flex-grow: 0 !important;
    }

    .width_tab_2_1 {
        min-width: 379px !important;
        flex-grow: 0 !important;
    }

    .width_tab_2_2 {
        min-width: 209px !important;
        flex-grow: 0 !important;
    }

    .transmission #tab2 .col-xxl-5 {
        max-width: 38.8%;
    }

    .transmission #tab2 .col-xxl-4 {
        max-width: 30.95%;
    }

    // .fixed_4{
    //     // top:52px !important;
    // }
    // .fixed_5{
    //     // top:87px !important;
    // }

    .width_tab_2_1.fixed_4 {
        min-width: 359px !important;
        margin-left: 8px !important;
    }

    .width_tab_2_2.fixed_4 {
        min-width: 192px !important;
        margin-left: 8px !important;
    }
}

// Formulaire
.react-grid-Cell {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

// Cession
//  #tbSynthese > tr {
// border-right: 2px solid gray
//  }

//  #tbSynthese > tr:not(:last-child) {
// border-left: 2px solid gray
//  }

// Header
.c-header-nav-link:hover {
    color: #1D51A1 !important
}

.dropdown-toggle.btn:hover {
    color: white !important;
    //background-color: #1D51A1;
    //border-radius: 20px;
}

// Header sticky
#link_onglet {
    position: sticky !important;
    top: 115px
}

.c-sidebar-minimized li.c-sidebar-nav-dropdown a.c-sidebar-nav-dropdown-toggle:hover {
    color: #5E656C !important;
    border-top-right-radius: 15px;
}

.c-sidebar li.c-sidebar-nav-dropdown a.c-sidebar-nav-dropdown-toggle,
.c-sidebar-minimized li.c-sidebar-nav-dropdown a.c-sidebar-nav-dropdown-toggle {
    color: #FFFFFF !important;
    font-weight: bold;
}

li.simu a.c-sidebar-nav-link {
    color: #D1F3FF !important
}

li.simu-alternative a.c-sidebar-nav-link {
    color: #FFFFFF !important;
    font-weight: bold
}

//.c-sidebar-minimized li.c-sidebar-nav-dropdown a.c-sidebar-nav-dropdown-toggle,
.c-sidebar-minimized li.c-sidebar-nav-item a.c-sidebar-nav-link {
    color: #5E656C !important
}

.c-sidebar-minimized li.c-sidebar-nav-item a.c-sidebar-nav-link:hover {
    color: #1D51A1 !important;
    background: white !important;
}

li.c-sidebar-nav-dropdown a.c-sidebar-nav-dropdown-toggle:hover,
li.c-sidebar-nav-item a.c-sidebar-nav-link:hover {
    color: #1990C1 !important
}

// Cession pointer
.pointer {
    position: relative;
    background: #3D4760 !important;
    padding-left: 20px;
    padding-right: 0px;
    color: white !important;
    cursor: pointer;
    // width: 195px;
    font-size: 12px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid white;
}

//menu fleche dans proposition financière
.pointer2 {
    position: relative;
    background: #3D4760 !important;
    padding-left: 20px;
    padding-right: 0px;
    color: white !important;
    cursor: pointer;
    width: 156px;
    font-size: 12px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid white;
}

.pointer-result {
    position: relative;
    background: rgb(248, 232, 232) !important;
    padding-left: 20px;
    padding-right: 0px;
    color: black !important;
    font-weight: bold;
    cursor: pointer;
    width: 195px;
    font-size: 12px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid white;
}

#menuFelcheBleu {
    box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px;
    margin-right: 15%;
}

.pointer.cession {
    width: 180px;
}

.pointer-result.cession {
    width: 180px;
}

.sidebar-show>.pointer.cession {
    width: 155px;
}

.sidebar-show>.pointer-result.cession {
    width: 155px;
}

.triKely {
    clip-path: polygon(0 0, 0% 100%, 33% 51%);
    background-color: #ffffff;
    display: block;
    width: 57px;
    height: 59px;
    margin-left: 0px;
    margin-top: -30px;
    position: absolute;
}

.triKely2 {
    clip-path: polygon(0 0, 0% 100%, 33% 51%);
    background-color: #ffffff;
    display: block;
    width: 57px;
    height: 59px;
    margin-left: 0px;
    margin-top: -30px;
    position: absolute;
}

.alokaFlecheKely {
    filter: drop-shadow(3px 2px 3px rgba(50, 50, 0, 0.1));
}

.petitFlecheVersLaDroite {
    filter: drop-shadow(3px 2px 3px rgb(248, 232, 232));
}

.pointer:hover,
.pointer2:hover,
.pointer-result:hover {
    background: #5C70A2 !important;
    color: rgb(255, 255, 255) !important;
}

.pointer.active,
.pointer2.active,
.pointer-result.active {
    background: white !important;
    color: #3D4760 !important;
    // border: 1px solid #5371B8;
    // border-right: none;
}

.pointer:not(:first-child):after,
.pointer-result:not(:first-child):after,
.pointer2:not(:first-child):after {
    content: "";
    position: absolute;
    left: -1px;
    bottom: 0;
    width: 0;
    height: 1;
    border-left: 19px solid #fff;
    border-top: 30px solid transparent;
    border-bottom: 29px solid transparent;
    z-index: 1;
}

.pointer:before,
.pointer2:before {
    content: "";
    position: absolute;
    right: -18px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 19px solid #3D4760;
    border-top: 30px solid transparent;
    border-bottom: 29px solid transparent;
    z-index: 2;
}


.pointer-result:before {
    content: "";
    position: absolute;
    right: -18px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 19px solid rgb(248, 232, 232);
    border-top: 30px solid transparent;
    border-bottom: 29px solid transparent;
    z-index: 2;
}

.pointer:hover:before,
.pointer-result:hover:before,
.pointer2:hover:before {
    border-left: 19px solid #5C70A2;
}

.pointer.active:before,
.pointer-result.active:before,
.pointer2.active:before {
    // border-left: 19px solid #D1D1D1;
    right: -18px;
    border-left: 19px solid rgb(255, 255, 255);
}

// Layout
.formulaire,
.simulation {
    margin-top: 8px;
}

//Donation
.formulaireDonation {
    margin-top: 20px;
}

// Sidebar

.c-sidebar-nav-item.active {
    background: #D8F5FF !important;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.c-sidebar-minimized .c-sidebar-nav-dropdown.active {
    background: #D8F5FF !important;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.c-sidebar-minimized .c-sidebar-nav-dropdown-toggle:hover {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.c-sidebar-nav-item.active .c-sidebar-nav-icon,
.c-sidebar-minimized .c-sidebar-nav-dropdown.active .c-sidebar-nav-icon,
.c-sidebar-nav-item.active .c-sidebar-nav-link,
.c-sidebar:not(.c-sidebar-minimized) .c-sidebar-nav-dropdown.active:not(.c-show) .c-sidebar-nav-dropdown-toggle,
.c-sidebar:not(.c-sidebar-minimized) .c-sidebar-nav-dropdown.active:not(.c-show) .c-sidebar-nav-dropdown-toggle>.c-sidebar-nav-icon {
    color: #1990C1 !important;
}

.c-sidebar-minimized .c-sidebar-nav-dropdown.active .c-sidebar-nav-dropdown-toggle {
    color: #5E656C !important;
}

.c-sidebar:not(.c-sidebar-minimized) .c-sidebar-nav-dropdown-items {
    overflow: hidden !important;
}

//BOX CESSION
.c-sidebar:not(.c-sidebar-minimized) .c-sidebar-nav-dropdown.active:not(.c-show),
.c-sidebar-minimized li.c-sidebar-nav-item a.c-sidebar-nav-link:hover {
    color: #5E656C !important;
    background: #D8F5FF !important;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.c-sidebar-minimized .c-sidebar-nav-dropdown.active li.c-sidebar-nav-item.active {
    background: #1990C1 !important;
}

.c-sidebar-minimized .c-sidebar-nav-dropdown.active li.c-sidebar-nav-item.active .c-sidebar-nav-link {
    color: white !important;
}

.c-sidebar-minimized .c-sidebar-nav-dropdown-toggle {
    font-weight: bold;
}

.c-sidebar-nav-item,
.c-sidebar-nav-link,
.c-sidebar-nav-dropdown {
    border-radius: 0px
}

.c-sidebar .c-sidebar-nav-dropdown-toggle:hover,
.c-sidebar .c-sidebar-nav-link:hover,
.c-sidebar-minimized li.c-sidebar-nav-dropdown ul.c-sidebar-nav-dropdown-items .c-sidebar-nav-item:hover .c-sidebar-nav-link {
    opacity: 1;
    transition: all 0.4s;
}

//Input Formulaire taux
html:not([dir="rtl"]) .was-validated .form-control:invalid,
html:not([dir="rtl"]) .form-control.is-invalid {
    background-position: 5px center;
    background: #fff;
}

.breadcrumb {
    padding: 0;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
}

.sommaire {
    border: 1px solid #cecece;
    outline: 1px solid #EAEBED;
    padding-top: 12px;
    margin-bottom: 40px;
    width: 70%;

    .title_sommaire {
        margin-left: 21px;
        margin-bottom: 5px;
        font-size: 19px;
        text-decoration: underline;
        font-weight: bold;
    }

    ul {
        li {
            font-size: 16px;

            a {
                text-decoration: none;
                color: #7030A0;
            }

            a:hover {
                text-decoration: underline;
            }
        }
    }
}

.scrollToTop {
    position: fixed;
    right: 55px; //35px
    width: 45px;
    border-radius: 47%;
    bottom: 70px;
    align-items: center;
    height: 19px;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    border: none;
    color: #fff
}


.PrivateTabIndicator-colorSecondary-67 {
    background-color: #1D51A1 !important;
}

.PrivateTabIndicator-colorSecondary-3 {
    background-color: #1D51A1 !important;
}

.welcome {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.actualite {
    margin-top: 15px;
    margin-bottom: 20px;
    margin-left: 15px;
    border-bottom: 3px solid #F04F6B;
    font-size: 17px;
    width: 100px;
}

.img-top {
    width: 62%;
}

.cardd {
    width: 30%;
}

.test {
    display: flex;
    margin: 0;
    padding: 0;
}

.container-mis {
    background: linear-gradient(180deg, white 150px, #F48456);
}

.read-more {
    position: absolute;
    margin-left: 282px;
    margin-top: -32px;
}

.btn-read {
    background-color: white;
    border: none;
    border-radius: 25px;
    width: 24px;
    opacity: 0.1;
}

#more {
    display: none;
}

#more2 {
    display: none;
}

#points {
    position: absolute;
    margin-left: 7px;
    margin-top: -26px;
}

.texts {
    display: inline;
    width: 100%;
}

.read-or-hide {
    color: rgb(192, 192, 192);
    cursor: pointer;
}

.pointu {
    position: absolute;
    clip-path: polygon(0 0, 0 100%, 100% 50%);
    background-color: red;
    width: 16px;
    height: 21px;
    margin-left: 145px;
    margin-top: -37px;
}

// @media only screen and (max-width: 768px){
//     .img-top,
//     .cardd{
//         width: 65%;
//     }
// }
// @media only screen and (max-width: 992px){
//     .img-top,
//     .cardd{
//         width: 75%;
//     }
// }
// @media only screen and (max-width: 1200px){
//     .img-top,
//     .cardd{
//         width: 75%;
//     }
// }

@media only screen and (max-width: 768px) {
    .titlesContent {
        margin-top: 20px;
        margin-bottom: -35px;
        font-size: 12px;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1199px) {
    .titlesContent {
        margin-top: 40px;
        margin-bottom: -35px;
        font-size: 12px;
    }

    .bloc3_content {
        padding-left: 0;
    }

    .bloc4_content {
        padding-left: 0;
    }

    .bloc5_content {
        margin-left: 0;
    }

    .bloc1 {
        padding-left: 0;
    }
}

@media only screen and (min-width: 1200px) {
    .titlesContent {
        margin-top: 70px;
    }

    .infos {
        width: 80%;
    }

    .bloc3_content {
        padding-left: 20px;
    }

    .bloc4_content {
        padding-left: 18px;
    }

    .bloc5_content {
        margin-left: 20px;
    }

    .bloc1 {
        padding-left: 20px;
    }
}

.card_tableau {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

#card_header.header-card.card-header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.bloc1,
.bloc4,
.bloc6 {
    box-shadow: rgba(33, 35, 38, 0.1) 0px 13px 13px -10px;
}

.arrow_box {
    position: relative;
    background: #EEEEEE;
    border: 1px solid #f52020;
    height: 80px;
    border-bottom-left-radius: 5px;
}

.arrow_box:after,
.arrow_box:before {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.arrow_box:after {
    border-color: rgba(255, 255, 255, 0);
    border-left-color: #EEEEEE;
    border-width: 8px;
    margin-top: -10px;
}

.arrow_box:before {
    border-color: rgba(245, 32, 32, 0);
    border-left-color: #f52020;
    border-width: 10px;
    margin-top: -12px;
}

.arrow_box p {
    text-align: center;
    margin-top: 28px;
    font-weight: bold;
    font-size: 14px;
}

.arrow_box2 {
    position: relative;
    background: #EEEEEE;
    border: 1px solid #5a5959;
    height: 80px;
    border-bottom-left-radius: 5px;
}

.arrow_box2:after,
.arrow_box2:before {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.arrow_box2:after {
    border-color: rgba(255, 255, 255, 0);
    border-left-color: #EEEEEE;
    border-width: 8px;
    margin-top: -10px;
}

.arrow_box2:before {
    border-color: rgba(245, 32, 32, 0);
    border-left-color: #5a5959;
    border-width: 10px;
    margin-top: -12px;
}

.arrow_box2 p {
    text-align: center;
    margin-top: 28px;
    font-weight: bold;
    font-size: 14px;
}

.MuiStepConnector-vertical {
    padding: 0 !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
    color: #7FC255 !important;
}

.tab_1 {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}


.divideur::after {
    content: " ";
    border-right: 1px solid #B4B4B4;
    height: 56px;
}

.divideurSecond::after {
    content: " ";
    border-right: 1px solid #B4B4B4;
    height: 28px;
    display: inline-block;
}

.ccard {
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
}

.borderpour::after {
    content: " ";
    width: 30px;
    height: 7px;
    border-radius: 2px;
    background-color: #2096fb;
}

.patri {
    background: linear-gradient(#189AC6, #1C57A3);
    clip-path: polygon(0 0, 85% 1%, 75% 100%, 0% 100%);
    width: 200px;
    height: 40px;
    margin-bottom: 15px;
    padding-top: 7px;
    padding-right: 39px;
    color: #fff;
    font-weight: bold;
    z-index: 1 !important;
}

.patriFI {
    background: #fff;
    clip-path: polygon(0 0, 85% 1%, 75% 100%, 0% 100%);
    width: 200px;
    height: 40px;
    margin-bottom: 15px;
    padding-top: 7px;
    padding-right: 39px;
    color: #717171;
    font-weight: bold;
    z-index: 1 !important;
}

.patriShadow {
    position: absolute;
    z-index: -1 !important;
    content: "";
    background: rgb(214 214 214);
    width: 200px;
    height: 40px;
    margin-left: 5px;
    margin-top: -55px;
    // top: 5px;
    filter: blur(5px);
    clip-path: polygon(0 0, 85% 1%, 75% 100%, 0% 100%);
}

.budget {
    background-color: #fff;
    clip-path: polygon(30% 1%, 100% 0, 90% 100%, 21% 100%);
    width: 213px;
    height: 40px;
    margin-left: -88px;
    padding-top: 7px;
    padding-bottom: -8px;
}

.budgetFI {
    background: linear-gradient(#189AC6, #1C57A3);
    clip-path: polygon(30% 1%, 100% 0, 90% 100%, 21% 100%);
    width: 213px;
    height: 40px;
    margin-left: -88px;
    padding-top: 7px;
    padding-bottom: -8px;
}

.pourcent {
    background: linear-gradient(#F16062, #EF4173);
    width: 90px;
    height: 59px;
    clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
    text-align: center;
    z-index: 1;
}

.pourcent2 {
    background: linear-gradient(#EE468B, #AD579F);
    width: 90px;
    height: 59px;
    clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
    text-align: center;
    z-index: 1;
}

.alok {
    filter: drop-shadow(-3px 2px 3px rgba(50, 50, 0, 0.3));
}

.tauxliquide {
    background-color: rgb(255, 255, 255);
    width: 260px;
    height: 59px;
    clip-path: polygon(75% 0%, 87% 50%, 75% 100%, 0% 100%, 10% 50%, 0% 0%);
    text-align: center;
    margin-left: -27px;
    z-index: -1;
}

.alokTaux {
    filter: drop-shadow(1px 2px 3px rgba(50, 50, 0, 0.1));
}

.doughnut {
    width: 82px;
    height: 82px;
    border-radius: 50%;
    // background-color: #7FC255;
}

.circle {
    position: absolute;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background-color: white;
    margin-left: 13px;
    margin-top: 13px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

.basfleche {
    content: " ";
    clip-path: polygon(100% 0, 0 0, 51% 100%);
    width: 15px;
    height: 20px;
    // background-color: #74B14E;
    margin-left: 34px;
    margin-top: -1px;
}

/**************PROFESSIONNEL****************/

.petitBlocPro {
    // box-shadow: 0px 0px 10px 0px rgb(237, 237, 237);
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    box-shadow: 2px 13px 54px #695F971C;
    border-radius: 30px;
    margin-bottom: 20px;
    position: relative;
}

.petiBlocProTitle {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  width: 100%;
}

.actifPro {

    clip-path: polygon(0 0, 85% 1%, 75% 100%, 0% 100%);
    width: 400px;
    height: 40px;
    margin-bottom: 15px;
    padding-top: 7px;
    padding-right: 39px;
    color: #fff;
    font-weight: bold;
    z-index: 1 !important;
}

.actifProShadow {
    position: absolute;
    z-index: -1 !important;
    content: "";
    background: #E9E9E9;
    width: 400px;
    height: 40px;
    margin-left: 7px;
    margin-top: -55px;
    // top: 5px;
    filter: blur(5px);
    clip-path: polygon(0 0, 85% 1%, 75% 100%, 0% 100%);
}

.intitulePers {
    background-color: #fff;
    // clip-path: polygon(30% 1%, 100% 0, 88% 100%, 19% 100%);
    // clip-path: polygon(8% 0, 82% 0, 73% 100%, 0% 100%);
    clip-path: polygon(11.4% -14px, 82.00% -16px, 73% 100%, 0% 100%);
    width: 500px;
    height: 40px;
    margin-left: -93px;
    padding-top: 7px;
    padding-bottom: -8px;
}

.intitulePersJ {
    background-color: #fff;
    // clip-path: polygon(30% 1%, 100% 0, 88% 100%, 19% 100%);
    clip-path: polygon(7% 0, 82% 0, 73% 100%, 7% 100%);

    width: 500px;
    height: 40px;
    margin-left: -93px;
    padding-top: 7px;
    padding-bottom: -8px;
}

.intitulePersShadow {
    position: absolute;
    z-index: -1 !important;
    content: "";
    margin-left: -85px;
    background: #ececec;
    width: 500px;
    height: 40px;
    margin-top: -40px;
    // top: 5px;
    filter: blur(5px);
    clip-path: polygon(8% 0, 82% 0, 73% 100%, 0% 100%);
}

.intitulePersShadowJ {
    position: absolute;
    z-index: -1 !important;
    content: "";
    margin-left: -85px;
    background: #ececec;
    width: 500px;
    height: 40px;
    margin-top: -40px;
    // top: 5px;
    filter: blur(5px);
    clip-path: polygon(7% 0, 82% 0, 73% 100%, 7% 100%);
}

.plus {
    color: #2179B2
}

.plus:active {
    // color: red;
    text-shadow: 0px 1px 2px rgb(124, 124, 124);
    transform: scale(1.2);
}

#intitulePers {
    border: none;
    background-color: inherit;
}

.config_div {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 4px;
    display: flex;
    box-sizing: border-box;
}

.import_data {
    display: flex;
    align-items: center;
    outline: none;
    background-color: rgb(118, 197, 10);
    border-radius: 4px;
    width: 100px;
    height: 35px;
    color: #fff;
    border: none;
    // margin-left: 316px;
    margin-top: 9px;
    // margin-bottom: 25px;
    min-width: 120px !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.import_data:active {
    outline: none;
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.3);
    transform: scale(1.1);
    border: none;
}

.import_data[disabled] {
    background-color: #cfcccc;
}

.renseigne {
    // max-width: 150px !important;
    margin-left: -44px !important;
    min-width: 56px !important;
}

.renseigLabel {
    min-width: 354px !important
}

.cocher {
    min-width: 286px !important
}

.boxClient {
    min-width: 580px !important;
}

input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    height: 30;
    width: 30;
    background-color: #ffffff;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d8d7d7
}

input[type="checkbox"]:after {
    content: url('../assets/icons/checki.svg');
    // background-image:  url('../assets/icons/check.png');
    // background-size: 18px 18px;
    display: none;
    width: 17px;
    height: 15px;
    // content:"";
}

input[type="checkbox"]:checked::after {
    display: block;
}

input[type="checkbox"]:disabled {
    background-color: #F4F5F7;
}


// PATRIMOINE SUCCESSION
.teteCadre {
    clip-path: polygon(0 0, 88% 0, 100% 100%, 0 100%);
    background-color: #F06160;
    width: 280px;
    height: 34px;
}

.teteCadre2 {
    clip-path: polygon(0 0, 98% 0, 100% 100%, 2% 100%);
    background-color: #F06160;
    width: 625px;
    margin-left: -65px;
    height: 34px;
}

.teteCadre2::before {
    content: "";
    background-color: rgb(255, 255, 255);
    width: 619px;
    height: 32px;
    clip-path: polygon(0 0, 98.1% 0, 100% 100%, 1.8% 100%);
    display: block;
    position: absolute;
    margin-top: 1px;
    margin-left: 4px;
}

.alokaShadow {
    filter: drop-shadow(4px 2px 3px rgba(20, 30, 11, 0.154));
}

//AV

.teteCadreAV {
    clip-path: polygon(0 0, 88% 0, 100% 100%, 0 100%);
    background-color: #EC468B;
    width: 226px;
    height: 34px;
}

.teteCadreAV2 {
    clip-path: polygon(0 0, 98% 0, 100% 100%, 2% 100%);
    background-color: #EC468B;
    width: 681px;
    margin-left: -65px;
    height: 34px;
}

.teteCadreAV2::before {
    content: "";
    background-color: white;
    width: 675px;
    height: 32px;
    clip-path: polygon(0 0, 98.1% 0, 100% 100%, 1.8% 100%);
    display: block;
    position: absolute;
    margin-top: 1px;
    margin-left: 4px;
}

.alokaShadowAV {
    filter: drop-shadow(4px 2px 3px rgba(20, 30, 11, 0.154));
}


//Patrimoine transmis

// .tetePatriTrans{
//     box-shadow: rgba(0, 0, 0, 6%) 2px 3px 4px 0px;
//     width: 1035px;
//     // margin-left: -17px;
// }

.total {
    padding: 0 !important;
    margin: 0 !important;
    margin-top: 8px !important;
}

.conjoint1 {
    clip-path: polygon(0 0, 93% 0, 100% 100%, 0% 100%);
    background-color: #318FCC;
    width: 325px;
    height: 64px;
}

.conjoint1::before {
    content: "";
    clip-path: polygon(0 0, 93% 0, 100% 100%, 0% 100%);
    background-color: #ffffff;
    width: 323px;
    height: 62px;
    display: block;
    position: absolute;
    margin-top: 1px;
    margin-left: 1px;
}

.conjoint2 {
    clip-path: polygon(0 0, 93% 0, 100% 100%, 0% 100%);
    background-color: #F47F4F;
    width: 325px;
    height: 64px;
}

.conjoint2::before {
    content: "";
    clip-path: polygon(0 0, 93% 0, 100% 100%, 0% 100%);
    background-color: #fff;
    width: 323px;
    height: 62px;
    display: block;
    position: absolute;
    margin-top: 1px;
    margin-left: 1px;
}

.shadowC1 {
    filter: drop-shadow(2px 3px 1px rgba(20, 30, 11, 0.254));
}

.xc {
    float: right;
}

.labels {
    min-width: 346px !important;
    max-width: 346px !important;
    margin-left: 20px;
}

.dd {
    max-width: 230px !important;
    min-width: 230px !important;
}

.champS {
    float: right;
    // max-width: 100px !important;
    // min-width: 100px !important;
    // margin-left: 100px;
}


.nb {
    padding: 0 !important;
}

.arrow-rigthPD {
    // width: 0;
    // height: 0px;
    // border-top: 114px solid transparent;
    // border-bottom: 110px solid transparent;
    // border-left: 39px solid #FFF;
    // position: relative;
    // z-index: 1;
    // padding-top: 8px;
    width: 0;
    height: 0px;
    border-top: 115px solid transparent;
    border-bottom: 117px solid transparent;
    border-left: 46px solid #ececec;
    position: relative;
    z-index: 4;
    padding-top: 0px;
    margin-left: 0px;

}

.arrow-rigthPD-aloka {
    width: 0;
    height: 0px;
    border-top: 112px solid transparent;
    border-bottom: 113px solid transparent;
    border-left: 44px solid #ffffff;
    position: relative;
    z-index: -2;
    padding-top: 0px;
    margin-top: -112px;
    margin-left: -46px;
    // width: 0;
    // height: 0px;
    // border-top: 114px solid transparent;
    // border-bottom: 110px solid transparent;
    // border-left: 42px solid rgb(226, 7, 7);
    // position: relative;
    // z-index: 1;
    // padding-top: 8px;

}

.petitbox {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.reserve_herid {
    box-shadow: rgb(0 0 0 / 4%) 3px 4px 2px -1px, rgb(0 0 0 / 8%) 2px -2px 4px -1px;
}

.doughnutOne {
    width: 53px;
    height: 53px;
    border-radius: 50%;
    background: linear-gradient(#AA589F, #AA589F);
    // background-color: #7FC255;
}

.doughnutOne2 {
    width: 53px;
    height: 53px;
    border-radius: 50%;
    background: linear-gradient(#EF4173, #F05C63);
    // background-color: #7FC255;
}

.circleOne {
    position: absolute;
    width: 39px;
    height: 39px;
    border-radius: 50%;
    background-color: white;
    margin-left: 7px;
    margin-top: 7px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

.flechiOne {
    content: " ";
    clip-path: polygon(100% 0, 0 0, 51% 100%);
    width: 11px;
    height: 15px;
    margin-left: 21px;
    margin-top: -1px;
}

.petitCard1 {
    background: linear-gradient(80deg, #EF4075, #F16260);
}

.petitCard2 {
    background: linear-gradient(80deg, #905FA7, #EC468B);
}

@media only screen and (min-width:1267px) {
    .tss {
        display: flex;
        flex-wrap: wrap;
    }

}

.content_graph {
    box-shadow: rgb(0 0 0 / 7%) 0px 3px 8px;
}

.content_devo {
    box-shadow: rgb(0 0 0 / 7%) 4px 0px 6px;
}

.arrow-graph {
    width: 0;
    height: 0px;
    border-top: 146px solid transparent;
    border-bottom: 144px solid transparent;
    border-left: 42px solid #eaeaea;
    position: relative;
    z-index: 4;
    padding-top: 0px;
    margin-left: 0px;
}

.arrow-graph-aloka {
    width: 0;
    height: 0px;
    border-top: 141px solid transparent;
    border-bottom: 137px solid transparent;
    border-left: 39px solid #ffffff;
    position: relative;
    z-index: -2;
    padding-top: 0px;
    margin-top: -140px;
    margin-left: -42px;
}


.arrow-AVC {
    width: 0;
    height: 0px;
    border-top: 63px solid transparent;
    border-bottom: 58px solid transparent;
    border-left: 25px solid #e8e8e8;
    position: relative;
    z-index: 4;
    padding-top: -2px;
    margin-left: 0px;
    margin-top: 0px;
}


.arrow-AVC-aloka {
    width: 0;
    height: 0px;
    border-top: 58px solid transparent;
    border-bottom: 54px solid transparent;
    border-left: 23px solid #ffffff;
    position: relative;
    z-index: -2;
    padding-top: 0px;
    margin-top: -58px;
    margin-left: -25px;
}

.arrow-AVC-aloka2 {
    width: 0;
    height: 0px;
    border-top: 58px solid transparent;
    border-bottom: 54px solid transparent;
    border-left: 23px solid #f4f4f4;
    position: relative;
    z-index: -2;
    padding-top: 0px;
    margin-top: -58px;
    margin-left: -25px;
}

.compo3,
.fleche1,
.firstParty {
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 7px;
}

.fleche2 {
    box-shadow: rgba(0 0 0 / 7%) 5px 3px 8px;
}

.sansImport {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 3px;
}


.content_PSG {
    margin-left: 25;
}

.teteC {
    clip-path: polygon(0 0, 88% 0, 100% 100%, 0 100%);
    background-color: #F06160;
    width: 393px;
    height: 34px;
}

.teteC2 {
    clip-path: polygon(0 0, 98% 0, 100% 100%, 2% 100%);
    background-color: #F06160;
    width: 609px;
    margin-left: -65px;
    height: 34px;
}

.teteC2::before {
    content: "";
    background-color: rgb(255, 255, 255);
    width: 603px;
    height: 32px;
    clip-path: polygon(0 0, 98.1% 0, 100% 100%, 1.8% 100%);
    display: block;
    position: absolute;
    margin-top: 1px;
    margin-left: 4px;
}

.alokaSh {
    filter: drop-shadow(4px 2px 3px rgba(20, 30, 11, 0.154));
}

.teteCAV {
    clip-path: polygon(0 0, 96.5% 0, 100% 100%, 0 100%);
    background-color: #EC468B;
    width: 341px;
    height: 45px;
}


.alokaShAV {
    filter: drop-shadow(4px 2px 3px rgba(20, 30, 11, 0.154));
}

.currencyInput {
    max-width: 250px;
    min-width: 195px;
}

.cadreLegal {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.inputLegataire {
    max-width: 200px;
    min-width: 200px;
}

.titleSuccession {
    clip-path: polygon(0% 0%, 93% 0, 100% 50%, 93% 100%, 0% 100%);
    background-color: #585C66;
}

.titleSuccession2 {
    clip-path: polygon(0% 0%, 93% 0, 100% 50%, 93% 100%, 0% 100%);
    background-color: #1C51A1;
}

.flechi {
    clip-path: polygon(0% 0%, 90% 0, 100% 50%, 89% 100%, 0% 100%);
    background-color: #fff;
}

.flechiAloka {
    clip-path: polygon(0% 0%, 91% 0, 100% 50%, 91% 100%, 0% 100%);
    background-color: #585C66;
}

.flechi2 {
    clip-path: polygon(93% 0, 100% 50%, 93% 100%, 0 100%, 6% 49%, 0% -3%);
    background-color: #fff;
}

.flechi2Aloka {
    clip-path: polygon(93% 0, 100% 50%, 93% 100%, 0 100%, 6% 49%, 0% -3%);
    background-color: #A5A5A5;
}

.flechi3 {
    clip-path: polygon(94% 0, 100% 50%, 94% 100%, 0 100%, 5% 50%, 0% 0%);
    background-color: #fff;
}


.alokaFleche {
    filter: drop-shadow(1px 2px 3px rgba(50, 50, 0, 0.1));
}

.mini {
    font-size: 13px;
}

.blockRevenu {
    // width: 467px;
    max-width: 467px;
    min-width: 467px;
}

.blockFirstGauche,
.blockSecondGauche {
    // width: 505px;
    // min-width: 505px;
    // max-width: 505px;
    width: 305px;
    min-width: 305px;
    max-width: 305px;
}

.styleCalcul:hover {
    transform: scale(0.9);
    transition: 0.5s;
    cursor: pointer;
}

@media only screen and (max-width: 3840px) {
    .spaceScenario {
        height: 32px;
    }
}

@media only screen and (max-width: 2560px) {
    .mini {
        font-size: 12px;
    }

    .blockRevenu {
        // width: 292px;
        max-width: 533px;
        min-width: 533px;
    }

    .plusToShow {
        margin-left: 535px;
    }

    .labelRevenu,
    .labelBncPro,
    .labelBicPro,
    .labelTraitement {
        max-width: 415px;
        width: 420px;
        min-width: 415px
    }

    .labelChargeDeductible {
        max-width: 438px;
        width: 438px;
        min-width: 438px
    }

    .labelTotalCSG {
        max-width: 438px;
        width: 453px;
        min-width: 453px
    }

    .labelCocher {
        min-width: 549px;
        max-width: 549px;
    }

    .petitBarreRouge {
        margin-left: 423px,
    }

    .petitBarreRouge2 {
        margin-left: 447px,
    }

    .labelPlafond {
        min-width: 501px;
        max-width: 501px;
    }

    .labelSelection {
        min-width: 670px;
        max-width: 670px;
    }

    .flecheOrange,
    .flecheCSG {
        min-width: 502px;
        max-width: 502px;
    }

    .miniForm {
        margin-left: 89px;
    }

    .nbEnfantTaille {
        width: 195.23px;
    }

    .contentToggle {
        margin-left: 1.5%;
    }

    .spaceScenario {
        height: 31px;
    }

}

@media only screen and (max-width: 1920px) {
    .labelPlafond {
        min-width: 248px;
        max-width: 248px;
    }

    .labelSelection {
        min-width: 417px;
        max-width: 417px;
    }

    .labelCocher {
        min-width: 520px;
        max-width: 520px;
    }

    .spaceScenario {
        height: 31px;
    }

}

@media screen and (max-width: 1730px) {
    .labelPlafond {
        min-width: 182px;
        max-width: 182px;
    }

}

@media only screen and (max-width: 1600px) {
    .mini {
        font-size: 9px;
    }

    .blockRevenu {
        // width: 292px;
        max-width: 226px;
        min-width: 226px;
    }

    .plusToShow {
        margin-left: 228px;
    }

    .labelRevenu,
    .labelBncPro,
    .labelBicPro {
        max-width: 396px;
        width: 396px;
        min-width: 396px
    }

    .labelChargeDeductible {
        max-width: 438px;
        width: 438px;
        min-width: 438px
    }

    .labelTraitement {
        max-width: 256px;
        width: 256px;
        min-width: 256px
    }

    .petitBarreRouge {
        margin-left: 404px,
    }

    .labelPlafond {
        min-width: 225px;
        max-width: 225px;
    }

    .labelSelection {
        min-width: 426px;
        max-width: 426px;
    }

    .styleCalcul {
        position: absolute;
        right: 140px;
        margin-top: 30px;
    }

    .titleRevenu {
        // font-size: 11px;
        // text-align: center;
        margin-left: 16px !important
    }

    .flecheOrange {
        min-width: 228px;
        max-width: 228px;
    }

    .flecheCSG {
        min-width: 278px;
        max-width: 278px;
    }

    .labelCocher {
        min-width: 524px;
        max-width: 524px;
    }

    .miniForm {
        margin-left: 53px;
    }

    .nbEnfantTaille {
        width: 179.36px;
    }

    .contentToggle {
        margin-left: 2.3%;
    }

    .spaceScenario {
        height: 31px;
    }

}

@media only screen and (max-width: 1522px) {
    .spaceScenario {
        height: 31px;
    }

    .labelSelection {
        min-width: 395px;
        max-width: 395px;
    }

    .labelCocher {
        min-width: 501px;
        max-width: 501px;
    }

}



@media only screen and (max-width: 1460px) {
    .mini {
        font-size: 9px;
    }

    .blockRevenu {
        // width: 292px;
        max-width: 226px;
        min-width: 226px;
    }

    .plusToShow {
        margin-left: 228px;
    }

    .labelRevenu,
    .labelBncPro,
    .labelBicPro {
        max-width: 396px;
        width: 396px;
        min-width: 396px
    }

    .labelChargeDeductible {
        max-width: 438px;
        width: 438px;
        min-width: 438px
    }

    .labelTraitement {
        max-width: 256px;
        width: 256px;
        min-width: 256px
    }

    .petitBarreRouge {
        margin-left: 404px,
    }

    .labelPlafond {
        min-width: 180px;
        max-width: 180px;
    }

    .labelSelection {
        min-width: 350px;
        max-width: 350px;
    }

    .styleCalcul {
        position: absolute;
        right: 110px;
        margin-top: 30px;
        width: 40px;
    }

    .titleRevenu {
        // font-size: 11px;
        // text-align: center;
        margin-left: 16px !important
    }

    .flecheOrange {
        min-width: 231px;
        max-width: 231px;
    }

    .flecheCSG {
        min-width: 278px;
        max-width: 278px;
    }

    .irpscehr {
        margin-left: -20px;
    }

    .labelCocher {
        min-width: 483px;
        max-width: 483px;
    }

    .miniForm {
        margin-left: 53px;
    }

    .nbEnfantTaille {
        width: 164.36px;
    }

}

@media only screen and (max-width: 1440px) {
    .spaceScenario {
        height: 31px;
    }
}

@media screen and (max-width: 1496px) {
    .spaceScenario {
        height: 31px;
    }
}

// @media only screen and (max-width: 1498px){
//     .spaceScenario{
//         height: 52px;
//     }
// }

@media only screen and (max-width: 1366px) {
    .labelCocher {
        min-width: 467px;
        max-width: 467px;
    }

}

@media only screen and (max-width: 1366px) {
    .spaceScenario {
        height: 31px;
    }
}

@media only screen and (max-width: 1298px) {
    .spaceScenario {
        height: 31px;
    }
}

// @media only screen and (max-width: 1280px){
//     .spaceScenario{
//         height: 52px;
//     }
// }

@media only screen and (max-width: 1250px) {
    .mini {
        font-size: 8px;
    }

    .blockRevenu {
        // width: 292px;
        max-width: 226px;
        min-width: 226px;
    }

    .plusToShow {
        margin-left: 228px;
    }

    .labelRevenu,
    .labelBncPro,
    .labelBicPro {
        max-width: 396px;
        width: 396px;
        min-width: 396px
    }

    .labelChargeDeductible {
        max-width: 438px;
        width: 438px;
        min-width: 438px
    }

    .labelTraitement {
        max-width: 256px;
        width: 256px;
        min-width: 256px
    }

    .petitBarreRouge {
        margin-left: 400px,
    }

    .labelPlafond {
        min-width: 180px;
        max-width: 180px;
    }

    .labelSelection {
        min-width: 351px;
        max-width: 351px;
    }

    .styleCalcul {
        position: absolute;
        right: 110px;
        margin-top: 30px;
    }

    .titleRevenu {
        // font-size: 11px;
        // text-align: center;
        margin-left: 16px !important
    }

    .flecheOrange {
        min-width: 265px;
        max-width: 265px;
    }

    .flecheCSG {
        min-width: 278px;
        max-width: 278px;
    }

    .irpscehr {
        margin-left: -20px;
    }

    .labelCocher {
        min-width: 467px;
        max-width: 467px;
    }

    .miniForm {
        margin-left: 53px;
    }

    .nbEnfantTaille {
        width: 164.36px;
    }

    .spaceScenario {
        height: 31px;
    }
}


@media only screen and (max-width: 1115px) {
    .mini {
        font-size: 7px;
    }

    .blockRevenu {
        // width: 292px;
        max-width: 226px;
        min-width: 226px;
    }

    .plusToShow {
        margin-left: 228px;
    }

    .labelRevenu,
    .labelBncPro,
    .labelBicPro {
        max-width: 396px;
        width: 396px;
        min-width: 396px
    }

    .labelChargeDeductible {
        max-width: 438px;
        width: 438px;
        min-width: 438px
    }

    .labelTraitement {
        max-width: 256px;
        width: 256px;
        min-width: 256px
    }

    .petitBarreRouge {
        margin-left: 405px,
    }

    .labelPlafond {
        min-width: 180px;
        max-width: 180px;
    }

    .styleCalcul {
        position: absolute;
        right: 110px;
        margin-top: 30px;
    }

    .labelSelection {
        min-width: 351px;
        max-width: 351px;
    }

    .titleRevenu {
        // font-size: 11px;
        // text-align: center;
        margin-left: 16px !important
    }

    .flecheOrange {
        min-width: 265px;
        max-width: 265px;
    }

    .flecheCSG {
        min-width: 278px;
        max-width: 278px;
    }

    .irpscehr {
        margin-left: -20px;
    }

    .labelCocher {
        min-width: 467px;
        max-width: 467px;
    }

    .miniForm {
        margin-left: 53px;
    }

    .nbEnfantTaille {
        width: 164.36px;
    }

    .spaceScenario {
        height: 31px;
    }

}

@media only screen and (max-width: 1032px) {
    .spaceScenario {
        height: 52px;
    }
}


@media only screen and (max-width: 850px) {
    .mini {
        font-size: 6px;
    }

    .blockRevenu {
        // width: 292px;
        max-width: 226px;
        min-width: 226px;
    }

    .plusToShow {
        margin-left: 228px;
    }

    .labelRevenu,
    .labelBncPro,
    .labelBicPro {
        max-width: 396px;
        width: 396px;
        min-width: 396px
    }

    .labelChargeDeductible {
        max-width: 438px;
        width: 438px;
        min-width: 438px
    }

    .labelTraitement {
        max-width: 256px;
        width: 256px;
        min-width: 256px
    }

    .petitBarreRouge {
        margin-left: 400px,
    }

    .labelPlafond {
        min-width: 180px;
        max-width: 180px;
    }

    .labelSelection {
        min-width: 370px;
        max-width: 370px;
    }

    .styleCalcul {
        position: absolute;
        right: 110px;
        margin-top: 30px;
    }

    .titleRevenu {
        // font-size: 11px;
        // text-align: center;
        margin-left: 16px !important
    }

    .flecheOrange {
        min-width: 265px;
        max-width: 265px;
    }

    .flecheCSG {
        min-width: 278px;
        max-width: 278px;
    }

    .irpscehr {
        margin-left: -20px;
    }

    .labelCocher {
        min-width: 467px;
        max-width: 467px;
    }

    .miniForm {
        margin-left: 53px;
    }

    .nbEnfantTaille {
        width: 164.36px;
    }

    .spaceScenario {
        height: 52px;
    }

}

.miniTriAngle {
    content: "";
    clip-path: polygon(0 0, 100% 0, 0 100%);
    background: #D63131;
    display: block;
    width: 22px;
    height: 20px;
    margin-top: 20px;
    position: absolute;
}

.resDetail {
    clip-path: polygon(0% 0%, 90% 0, 100% 50%, 90% 100%, 0% 100%);
    display: "block";
    width: 250px;
    height: 70px;
}

.resDetail:hover {
    // transform: translateX(12%);
    // transform-origin: left;
    width: 300px;
    transition: 0.3s;
}

.foyer p {
    margin-top: 0 !important;
}

.btnBlue {
    transform: rotate(180deg);
}

.calculat:hover {
    transform: scale(0.9);
    transition: 0.5s;
    cursor: pointer;
}

.CardSimu {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

// Hover dans la liste des simulateurs autonomes
.lien:hover {
    border: none;
    color: white;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, .26) !important;

    .barProgress {
        background: #E1E1E1 !important;
    }
}



// Quand la souris est sur le PEA
.lienPEA:hover {
    background: #F04F6C;
}

// Quand la souris est sur le CT
.lienCT:hover {
    background: #179BC5;
}

// Quand la souris est sur le AV
.lienAV:hover {
    background: #EDC413
}

// Quand la souris est sur le CAPI
.lienCAPI:hover {
    background: #87C660;
}

// Quand la souris est sur le IS
.lienIS:hover {
    background: #8E5FA8;
}


.aidePicto:hover {
    transform: scale(0.86);
    transition: 0.6s;
}

.triAngle {
    clip-path: polygon(0 0, 0% 100%, 50% 50%);
}

.toggleVerticale {
    transform: rotate(90deg);
    width: 0px;
    position: absolute;
    margin-top: -39px;
    margin-left: 340px;
    z-index: 1;
}

.toggleVerticalecjt {
    transform: rotate(90deg);
    width: 0px;
    position: absolute;
    margin-top: -39px;
    margin-left: 535px;
    z-index: 1;
}

.toggleVerticaleEnf {
    transform: rotate(90deg);
    position: absolute;
    top: -8px;
    left: -5px;
    z-index: 1;
    display: flex;
    flex-direction: column;

}

.compareBlock {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.structureBlock {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.modeCalculCard {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.tab_2 .react-grid-HeaderRow {
    left: 8px !important;
    // width: auto !important;
}

.react-grid-HeaderCell {
    display: flex !important;
    justify-content: center !important;
}

.react-grid-HeaderCell .header {
    width: 46% !important;
    // margin-left: 49px !important;
}

// .max_width_none{
//     margin-left: 10px !important;
// }

.menuScene a {
    background: linear-gradient(285deg, #8D5FA8, #1E51A1)
}

.menuScene2 a {
    background: linear-gradient(285deg, #EC468B, #8D5FA8)
}

.menuScene3 a {
    background: linear-gradient(285deg, #FF7A22, #EC468B)
}

.menuScene4 a {
    background: linear-gradient(285deg, #EDC413, #FF7A22)
}

.menuScene a:hover,
.menuScene2 a:hover,
.menuScene3 a:hover,
.menuScene4 a:hover {
    background: linear-gradient(285deg, #7D7D7D, #B4B4B4);
}

.menuScene a:active {
    background: linear-gradient(285deg, #7D7D7D, #B4B4B4);
}

.activeScenarioCession {
    background: linear-gradient(285deg, #7D7D7D, #B4B4B4);
}


.filActualite {
    background: linear-gradient(178deg, #EF3F75, #FF7A21);
}

.miniTriangle {
    clip-path: polygon(0 0, 0 100%, 100% 0);
    background: #FF7A21;
    width: 11px;
    height: 9px;
    margin-top: -1px;
}

.entetePatri {
    position: sticky !important;
    z-index: 1;
    top: 167px;
    margin-left: 0px
}

#tableauApportsAvantCession .react-grid-Header {
    background-color: #fff !important;
}

// #tableauApportsAvantCession .react-grid-Cell:nth-child(2){
//     border-left: none !important;
//      border-right: none !important;

// }

.simula1:hover,
.simula2:hover,
.simula3:hover,
.homeFi1:hover,
.homeFi2:hover,
.homeFi3:hover,
.HDonatSucc1:hover,
.HDonatSucc2:hover,
.HDonatSucc3:hover,
.patriFisca1:hover,
.patriFisca2:hover {
    transform: scale(1.05);
    transition: 0.6s;
    cursor: pointer;
}

.menu-icon-patrimoine-fiscalite:hover {
    transform: scale(1.05);
    transition: 0.6s;
    cursor: pointer;
}

@media all and (min-width: 600px) and (max-width: 1200px) {
    .fondBanane {
        width: 380px;
    }

    .simula1 {
        width: 173px;
        margin-top: 20%;
        margin-left: -135%;
        position: absolute;
    }

    .simula2 {
        width: 147px;
        margin-top: 72%;
        margin-left: -202%;
        position: absolute;
    }

    .simula3 {
        width: 147px;
        margin-top: 95%;
        margin-left: -104%;
        position: absolute;
    }

    .styly {
        position: absolute;
        width: 51px;
        margin-top: 152%;
        margin-left: -93%;
    }

    .grandTitle {
        font-size: 30px;
    }

    .sousTitle {
        font-size: 15px;
        margin-left: 22px;
    }

    .guillemet {
        width: 20px;
    }

    //Investissement financier
    .homeFi1 {
        width: 159px;
        margin-top: 14%;
        margin-left: -122%;
        position: absolute;
    }

    .homeFi2 {
        width: 169px;
        margin-top: 56%;
        margin-left: -205%;
        position: absolute;
    }

    .homeFi3 {
        width: 184px;
        margin-top: 88%;
        margin-left: -88%;
        position: absolute;
    }

    //Donation & Succession
    .HDonatSucc1 {
        width: 154px;
        margin-top: 8%;
        margin-left: -128%;
        position: absolute;
    }

    .HDonatSucc2 {
        width: 189px;
        margin-top: 48%;
        margin-left: -209%;
        position: absolute;
    }

    .HDonatSucc3 {
        width: 207px;
        margin-top: 77%;
        margin-left: -87%;
        position: absolute;
    }

    //Patrimoine et fiscalite
    .patriFisca1 {
        width: 173px;
        margin-top: 51%;
        margin-left: -198%;
        position: absolute;
    }

    .patriFisca2 {
        width: 179px;
        margin-top: 68%;
        margin-left: -94%;
        position: absolute;
    }

}

@media all and (min-width: 1201px) and (max-width: 1365px) {
    .fondBanane {
        width: 450px;
    }

    .simula1 {
        width: 202px; //272px;
        margin-top: 44px; // 15%;
        margin-left: -286px; // -99%;
        position: absolute;
    }

    .simula2 {
        width: 169px; // 224px;
        margin-top: 157px; // 54%;
        margin-left: -430px; // -143%;
        position: absolute;
    }

    .simula3 {
        width: 174px; // 224px;
        margin-top: 200px; //69%;
        margin-left: -86%;
        position: absolute;
    }

    .styly {
        position: absolute;
        width: 64px;
        margin-top: 127%;
        margin-left: -80%;
    }

    .grandTitle {
        font-size: 30px;
    }

    .sousTitle {
        font-size: 15px;
        margin-left: 22px;
    }

    .guillemet {
        width: 20px;
    }

    //Investissement financier
    // .homeFi1{
    //     width: 180px;
    //     margin-top: 6%;
    //     margin-left: -112%;
    //     position: absolute;
    // }
    // .homeFi2{
    //     width: 192px;
    //     margin-top: 44%;
    //     margin-left: -174%;
    //     position: absolute;
    // }
    // .homeFi3{
    //     width: 210px;
    //     margin-top: 63%;
    //     margin-left: -77%;
    //     position: absolute;
    // }
    //Investissement financier
    .homeFi1 {
        width: 154px;
        margin-top: 14%;
        margin-left: -112%;
        position: absolute;
    }

    .homeFi2 {
        width: 170px;
        margin-top: 55%;
        margin-left: -174%;
        position: absolute;
    }

    .homeFi3 {
        width: 189px;
        margin-top: 73%;
        margin-left: -77%;
        position: absolute;
    }

    //Donation & Succession
    .HDonatSucc1 {
        width: 176px;
        margin-top: 2%;
        margin-left: -105%;
        position: absolute;
    }

    .HDonatSucc2 {
        width: 225px;
        margin-top: 35%;
        margin-left: -178%;
        position: absolute;
    }

    .HDonatSucc3 {
        width: 240px;
        margin-top: 62%;
        margin-left: -70%;
        position: absolute;
    }

    //Patrimoine et fiscalite
    .patriFisca1 {
        width: 173px;
        margin-top: 56%;
        margin-left: -166%;
        position: absolute;
    }

    .patriFisca2 {
        width: 186px;
        margin-top: 64%;
        margin-left: -75%;
        position: absolute;
    }


}


@media all and (min-width: 1366px) and (max-width: 1920px) {


    .fondBanane {
        width: 530px; //600px;
    }

    .simula1 {
        width: 221px;
        margin-top: 15%;
        margin-left: -100%;
        position: absolute;
    }

    .simula2 {
        width: 176px;
        margin-top: 55%;
        margin-left: -152%;
        position: absolute;
    }

    .simula3 {
        width: 180px;
        margin-top: 74%;
        margin-left: -73%;
        position: absolute;
    }

    .styly {
        position: absolute;
        width: 74px;
        margin-top: 112%;
        margin-left: -69%;
    }

    .grandTitle {
        font-size: 30px;
    }

    .sousTitle {
        font-size: 15px;
        margin-left: 22px;
    }

    .guillemet {
        width: 20px;
    }

    //Investissement financier
    .homeFi1 {
        width: 195px; //203px;
        margin-top: 14%; //8%;
        margin-left: -92%; //-96%;
        position: absolute;
    }

    .homeFi2 {
        width: 210px; //218px;
        margin-top: 46%; //42%;
        margin-left: -155%;
        position: absolute;
    }

    .homeFi3 {
        width: 228px; //240px;
        margin-top: 64%; // 59%;
        margin-left: -70%; //-71%;
        position: absolute;
    }

    //Donation & Succession
    .HDonatSucc1 {
        width: 197px;
        margin-top: 10%;
        margin-left: -99%;
        position: absolute;
    }

    .HDonatSucc2 {
        width: 260px;
        margin-top: 41%;
        margin-left: -157%;
        position: absolute;
    }

    .HDonatSucc3 {
        width: 275px;
        margin-top: 56%;
        margin-left: -64%;
        position: absolute;
    }

    //Patrimoine et fiscalite
    .patriFisca1 {
        width: 219px;
        margin-top: 43%;
        margin-left: -150%;
        position: absolute;
    }

    .patriFisca2 {
        width: 231px;
        margin-top: 52%;
        margin-left: -62%;
        position: absolute;
    }
}

@media all and (min-width: 1921px) and (max-width: 2560px) {


    .fondBanane {
        width: 1000px;
    }

    .simula1 {
        width: 432px;
        margin-top: 12%;
        margin-left: -80%;
        position: absolute;
    }

    .simula2 {
        width: 359px;
        margin-top: 42%;
        margin-left: -119%;
        position: absolute;
    }

    .simula3 {
        width: 353px;
        margin-top: 56%;
        margin-left: -61%;
        position: absolute;
    }

    .styly {
        position: absolute;
        width: 152px;
        margin-top: 86%;
        margin-left: -58%;
    }

    .grandTitle {
        font-size: 70px;
    }

    .sousTitle {
        font-size: 25px;
        margin-left: 46px;
    }

    .guillemet {
        width: 43px;
    }

    //Investissement financier
    .homeFi1 {
        width: 398px;
        margin-top: 8%;
        margin-left: -73%;
        position: absolute;
    }

    .homeFi2 {
        width: 419px;
        margin-top: 32%;
        margin-left: -121%;
        position: absolute;
    }

    .homeFi3 {
        width: 465px;
        margin-top: 48%;
        margin-left: -57%;
        position: absolute;
    }

    //Donation & Succession
    .HDonatSucc1 {
        width: 394px;
        margin-top: 6%;
        margin-left: -76%;
        position: absolute;
    }

    .HDonatSucc2 {
        width: 509px;
        margin-top: 30%;
        margin-left: -123%;
        position: absolute;
    }

    .HDonatSucc3 {
        width: 507px;
        margin-top: 47%;
        margin-left: -51%;
        position: absolute;
    }

    //Patrimoine et fiscalite
    .patriFisca1 {
        width: 422px;
        margin-top: 32%;
        margin-left: -117%;
        position: absolute;
    }

    .patriFisca2 {
        width: 422px;
        margin-top: 40%;
        margin-left: -52%;
        position: absolute;
    }
}

.popup-box {
    position: fixed;
    // background: #dddddd50;
    width: 305px;
    height: 83.5vh;
    top: 56px;
    right: 0;
    z-index: 1;
}

.box {
    position: relative;
    width: 100%;
    /* margin: 0 auto; */
    height: 100%;
    margin-top: calc(100vh - 85vh - 20px);
    background: #ffffff;
    border-radius: 6px;
    padding: 20px;
    border: 1px solid #e9e9e9;
    overflow: auto;
    margin-top: 0px;
}

@media screen and (min-width: 1200px) {
    .content_PG {
        width: 98%;
    }

    .avBlock {
        min-width: 315px;
        max-width: 315px;
    }
}

@media screen and (min-width: 1366px) {
    .content_PG {
        width: 85%;
    }

    .avBlock {
        min-width: 315px;
        max-width: 315px;
    }
}

@media screen and (min-width: 1600px) {
    .content_PG {
        width: 1000px;
    }

    .avBlock {
        min-width: 315px;
        max-width: 315px;
    }
}

@media screen and (min-width: 1597px) and (max-width: 1920px) {
    .ContenTrasmis {
        width: 1200px;
    }
}

@media screen and (min-width: 1368px) and (max-width: 1920px) {
    .MyBlockTitle {
        max-width: 315px;
        min-width: 315px;
    }

    .content_PG {
        width: 1000px;
    }

    .avBlock {
        min-width: 315px;
        max-width: 315px;
    }
}

@media screen and (min-width: 2560px) {
    .content_PG {
        width: 1000px //43.5%;
    }

    .ContenTrasmis {
        width: 1200px;
    }

    .MyBlockTitle {
        max-width: 315px;
        min-width: 315px;
    }

    .avBlock {
        min-width: 315px;
        max-width: 315px;
    }
}

.animated {
    animation-duration: 0.7s;
    animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}

.entetePatriTransmis {
    position: sticky !important;
    z-index: 1;
    top: 167px
}

.picto_cs:hover {
    transform: scale(1.05);
    transition: 0.6s;
    cursor: pointer;
}
