.abbove-fixed-right-bottom-button-ancien {
  position: fixed;
  bottom: 80px;
  right: 20px;
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #0056b3;
  }
}

.abbove-fixed-right-bottom-button {
  border: none;
  background: transparent;

  &:focus, &:focus-visible {
    outline: none;
  }
}

.abbove-container {
  display: flex;
  overflow: auto;
  padding: 20px;

  & h2, & h3, & h4 {
    font-weight: bold;
  }

  &-content {
    width: 100%;
    display: flex;
    gap: 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  & a {
    border: none;
    font-weight: bold;
    background: #3595ff;
    color: #fff;
    padding: 14px 24px;
    border-radius: 7px;
  }
}
