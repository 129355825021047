// Composant parent des menus
.simulateur-top-menu-bis .menu{
    width: 100%;
    display: flex;
    // border-bottom: 2px solid #D9E1E7;
}

// Les différents menus (actifs ou non)
.simulateur-top-menu-bis .menu .menu-item,
.simulateur-top-menu-bis .menu .menu-item-active{
    min-width: 100px;
    height: 40px;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 11px;
    padding-right: 11px;
    color: #3D4760;
    line-height: 16px;
    text-transform: uppercase;
}

.simulateur-top-menu-bis .menu .menu-item{
    border-bottom: 2px solid #D9E1E7;
    border-radius: 10px 10px 0 0;
    background: rgb(229,230,233)
}

// Menu actif ou au survol de la Souris
.simulateur-top-menu-bis .menu .menu-item-active{
    background: #3D4760;
    color: #FFFFFF;
    font-weight: bold;
    border-radius: 10px 10px 0 0;
}

.simulateur-top-menu-bis .menu .menu-item:hover{
    // border-radius: 10px 10px 0 0;
    // background: red
    color: #1D51A1
}

// Spécifiquement au survol de la souris
.simulateur-top-menu-bis .menu .menu-item:hover{
    cursor: pointer
}

// Contenu
.simulateur-top-menu-bis .contenu{
    width: 100%;
    background: #FFFFFF;
    padding: 11px;
    // box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    margin-bottom: 10px
}