.root{
    margin-top: 15px;
    background-color: "white";
    //padding-bottom: 25px;
    border-radius: 13px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    display: flex;
    flex-direction: column;
}

.title{
    font-weight: bold;
    margin-top: 0;
    border-radius: 13px 13px 0 0;
    padding: 10px;
    padding-left: 16px;
    font-size: 15px;
    text-transform: "uppercase";
    background-color: #FFFFFF;
}

.vert{
    width: 28px;
    height: 5px;
    border-radius: 2px;
    background-color: rgb(84, 169, 80);
}

.bloc{
    display: flex;
    flex-direction: row;
    height: 335px;
    background-color: #FFFFFF;
    // margin-bottom: 25px;
}

.gauche{
    width: 55%;
    margin-right: 5px;
    background-color: #fafafa;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.droite{
    width: 45%;
    background-color: #fafafa;
}

.haut{
    border-bottom: 4px solid #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 15px;
}

.bas{
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 15px;
}

.titreBlocHautDroite{
    font-weight: bold;
    margin-bottom: 0;
    margin-left: 39px;
}
