#tableauApportsAvantCession .react-grid-HeaderCell{
    background: #FFFFFF;
    border-right: none !important;
}

#tableauApportsAvantCession .react-grid-Cell:nth-child(3){
    border-left: 4px solid white;
}

#tableauApportsAvantCession .react-grid-HeaderCell:nth-child(1){
    border-right: none;
}

#tableauApportsAvantCession .react-grid-HeaderCell:nth-child(2){
    border-right: none;
}

#tableauApportsAvantCession .react-grid-HeaderCell:nth-child(4){
    border-right: none;
}

// #tableauApportsAvantCession .react-grid-HeaderCell:nth-child(5){
//     border-right: none;
// }

#tableauApportsAvantCession .react-grid-HeaderCell:nth-child(2){
    background: #585C66;
    color: #FFFFFF;
    border: 2px solid #585C66;
}

#tableauApportsAvantCession .react-grid-Row:not(:last-child) .react-grid-Cell:nth-child(2){
    border-left: 2px solid #585C66;
    border-right: 2px solid #585C66;
}

// #tableauApportsAvantCession .react-grid-Row:nth-child(1) .react-grid-Cell,
// #tableauApportsAvantCession .react-grid-Row:nth-child(2) .react-grid-Cell,
// #tableauApportsAvantCession .react-grid-Row:nth-child(3) .react-grid-Cell{
//     background-color: #F3F3F3;
//     border-bottom: 1px solid white;
// }

#tableauApportsAvantCession .react-grid-Row .react-grid-Cell:nth-child(2){
    background-color: #fff;
}

#tableauApportsAvantCession .react-grid-Cell__value{
    border-right: 4px solid #fff;
}

#tableauApportsAvantCession .react-grid-Row:last-child .react-grid-Cell:nth-child(2){
    border-left: 2px solid rgb(89,89,89);
    border-right: 2px solid rgb(89,89,89);
}
#tableauApportsAvantCession .react-grid-Row:last-child  .react-grid-Cell__value:nth-child(2){
    border-right: none !important;
}

#tableauApportsAvantCession .react-grid-Cell:nth-child(1){
    text-align: left;
    border-right: none !important;
    // padding-left: 15px !important;
    padding-right: 0px !important;
}

#tableauApportsAvantCession .react-grid-Cell:nth-child(2){
    text-align: right;
    border-right: none;
    // padding-right: 5px !important;
}

#tableauApportsAvantCession .react-grid-Cell:nth-child(3){
    text-align: right;
    padding-right: 0px !important;
}

#tableauApportsAvantCession .react-grid-Cell:nth-child(4){
    text-align: right;
    padding-right: 0px !important;
    border-right: none;
}

// #tableauApportsAvantCession .react-grid-Cell:nth-child(5){
//     text-align: right;
//     padding-right: 20px !important;
//     border-right: none;
// }

#tableauApportsAvantCession  .react-grid-Row:last-child .react-grid-Cell:nth-child(1){
    color: #FFFFFF;
    font-weight: bold;
    background-color: rgb(89,89,89);
    // border-right: 2px solid white !important;
}

#tableauApportsAvantCession  .react-grid-Row:last-child .react-grid-Cell:nth-child(2){
    color: #FFFFFF;
    font-weight: bold;
    background-color: rgb(89,89,89);
}

#tableauApportsAvantCession  .react-grid-Row:last-child .react-grid-Cell:nth-child(3){
    color: #FFFFFF;
    font-weight: bold;
    background-color: rgb(89,89,89);
    // border-left: 2px solid white !important;
}

#tableauApportsAvantCession  .react-grid-Row:last-child .react-grid-Cell:nth-child(4){
    color: #FFFFFF;
    font-weight: bold;
    background-color: rgb(89,89,89);
    // border-left: 1px solid white !important;
}

// #tableauApportsAvantCession  .react-grid-Row:last-child .react-grid-Cell:nth-child(5){
//     color: #FFFFFF;
//     font-weight: bold;
//     background-color: rgb(89,89,89);
// }

// Bordure ORANGE de la fin de la ligne
#tableauApportsAvantCession .react-grid-Canvas > div:nth-of-type(2) > div:last-of-type .react-grid-Row > .react-grid-Cell:nth-child(3) {
    border-bottom: 2px solid #1D51A1;
}

#tableauApportsAvantCession .react-grid-Grid{
    border: none !important
}

#tableauApportsAvantCession .react-grid-Main{
    outline: none !important
}

#tableauApportsAvantCession .react-grid-HeaderCell--frozen:last-of-type{
    box-shadow: none !important;
}

#tableauApportsAvantCession .react-grid-Cell{
    
    border-bottom: 1px solid #f3f3f3;
}

#tableauApportsAvantCession .rdg-last--frozen{
    box-shadow: none !important;
    border-right: none !important;
}
