$pin-top: -8px;
$pin-right: -7px;
$pin-top-hover: -11px;
$pin-right-hover: -11px;
$pin-width: 17px;
$pin-width-comment: 27px;
$pin-width-hover: 27px;
$pin-height-hover: 27px;

.post-it {
  &-pin-container {
    position: absolute;
    top: var(--pin-top);
    right: var(--pin-right);
    z-index: 1;

    &.not-active:hover {
      top: var(--pin-top-hover);
      right: var(--pin-right-hover);
    }
  }

  &-pin-yellow {
    width: $pin-width;
    transition: color ease .5s;

    &-hover {
      width: $pin-width-comment;
    }

    &:hover {
      height: $pin-height-hover;
      width: $pin-width-hover;
      background: #FFA205;
      border-radius: 50%;
      padding: 5px;

      & path {
        fill: #fff;
      }
    }
  }

  &-pin-gray {
    width: $pin-width;
    transition: color ease .5s;

    &-hover {
      width: $pin-width-comment;
    }

    &:hover {
      height: $pin-height-hover;
      width: $pin-width-hover;
      background: #8d979c;
      border-radius: 50%;
      padding: 5px;

      & path {
        fill: #fff;
      }
    }
  }
}
