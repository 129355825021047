.cadreRose{
    width: 115px;
    height: 5px;
    border-radius: 2px;
    background-color: rgb(238,70,139);
}

.cadreRouge{
    width: 150px;
    height: 5px;
    border-radius: 2px;
    background-color: rgb(241,99,96);
}

.add-chevron-right {
  display: flex;
  align-items: center;
  color: #fff;
  background: rgb(23, 157, 199);
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.chevron::before {
  border-style: solid;
  border-width: 0.15em 0.15em 0 0;
  content: '';
  display: inline-block;
  height: 0.45em;
  position: relative;
  top: 6px;
  transform: rotate(-45deg);
  vertical-align: top;
  width: 0.45em;
}

.chevron.right:before {
	left: 4px;
	transform: rotate(45deg);
}
