// Example 2.
@mixin cross($size: 20px, $color: currentColor, $thickness: 1px) {
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  position: relative;
  width: $size;
  height: $size;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: ($size - $thickness) / 2;
    left: 0;
    right: 0;
    height: $thickness;
    background: $color;
    border-radius: $thickness;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  span {
    display: block;
  }

}

.cross-stand-alone-container {
  display: flex;
  position: relative;
  cursor: pointer;
}

.cross-stand-alone {
  @include cross(20px, #000, 3px);
}

.popup-confirmation {

  font-family: 'roboto';

  z-index: 5000;

  &-header {
    color: #5E7384;
    text-transform: uppercase;
    font-weight: bold;
  }

  &-body {
    color: #5E7384;
  }

  .modal-header {
    padding-bottom: 0;
  }

  .modal-body {
    font-size: 14px;
  }

  .modal-footer {
    padding-top: 0;
  }

  .modal-content {
    background: #CCDDEB;
    border: 1px solid #8EB7C7;
    border-radius: 10px;
  }
}
