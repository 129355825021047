.tableau{
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
}

.dataTitre{
    display: flex;
    color: #FFFFFF;
    font-weight: bold;
    width: 98%;
    height: 55px;
    padding: 5px;
    position: sticky !important;
    top: 111px
}

.data{
    display: flex;
    color: rgb(88, 92, 102);
    font-weight: bold;
    width: 98%;
    height: 35px;
    font-size: 13px;
    padding-left: 5px;
    padding-right: 5px;
    background-color: #FFFFFF;
}

.data:hover{
    background-color: rgb(235,234,234);
}