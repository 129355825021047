.toggle-switchGreen {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 27px;
  }
  .toggle-switchGreen input[type="checkbox"] {
    display: none;
  }
  .toggle-switchGreen .switchGreen {
    position: absolute;
    cursor: pointer;
    background:  linear-gradient(180deg,#878787,#ACACAC);
    border: 2px solid rgb(255, 255, 255);
    outline: 1px solid #D1D2D4;
    border-radius: 25px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.2s ease;
  }
  .toggle-switchGreen .switchGreen::before {
    position: absolute;
    content: "";
    left: 2px;
    top: 1px;
    width: 21px;
    height: 21px;
    background: linear-gradient(90deg,rgb(197, 197, 197),#fff);
    border-radius: 50%;
    transition: transform 0.3s ease;
    border: 1px solid rgb(238, 238, 238);
    
  }
  .toggle-switchGreen input[type="checkbox"]:checked + .switchGreen::before {
    transform: translateX(31px);
    background: linear-gradient(90deg,rgb(197, 197, 197),#fff);
  }
  .toggle-switchGreen input[type="checkbox"]:checked + .switchGreen {
    background:  #82BB4B;
    border: 2px solid rgb(255, 254, 254);
  }
  