.shortcut-simulation {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-right: 27px;
  position: relative;

  &:hover {
    background: #D6E0EA !important;
    border-radius: 50%;
    transition: .5s all;
  }

  &-btn {
    cursor: pointer;
  }

  &-content {
    position: absolute;
    top: 50px;
    left: -36px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    gap: 25px;
    border-radius: 6px;
    padding: 15px;
    border: 1px solid #E9E9E9;
    width: 118px;
    font-size: 13px;

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
        opacity: .7;
        transition: .5s all;
      }

      &-label {
        display: 'flex';
        justify-content: 'center';
        align-items: 'center';
        text-align: center;
      }
    }
  }

}
